import  {FC, useEffect} from 'react'
import { getOrganisationIdFromURL, isAdmin, isMSLCUser, isOmega } from './HelperComponents/Functions'

interface Props 
{
    data: Data
}

interface Data 
{
    config:Config,
    id:string,
    isAuthenticated:Boolean,
    roleId: number | null
}

interface Config
{
    headers:Headers
}

interface Headers
{
    Authorization:string
}

const OmegaPanel:FC<Props> = ({data}) => 
{
    const organisationId = getOrganisationIdFromURL();

    useEffect(()=>
    {

    },[data.roleId])    

    return (
        <div className='container mb-5'>
            {
                isAdmin(data.roleId)?
                <div>
                    <a href={'/admin/parent?appId='+organisationId} className='btn btn-link'>
                        <div className="alert alert-primary mt-5" role="alert">
                        Parent
                        </div>
                    </a>
                    
                    <a href={'/admin/student?appId='+organisationId} className='btn btn-link'>
                        <div className="alert alert-danger mt-5" role="alert">
                        Student
                        </div>
                    </a>
                    
                     <a href={'/admin/lesson?appId='+organisationId} className='btn btn-link'>
                        <div className="alert alert-warning mt-5" role="alert">
                        Lesson
                        </div>
                    </a> 
                     <a href={'/admin/invoice-panel?appId='+organisationId} className='btn btn-link'>
                        <div className="alert alert-primary mt-5" role="alert">
                        Invoice
                        </div>
                    </a> 
                    {
                        isMSLCUser(organisationId, data.roleId) || !isAdmin(data.roleId)?
                        null
                        : 
                        <div>
                            <a href={'/admin/taken-lesson?appId='+organisationId} className='btn btn-link'>
                                <div className="alert alert-info mt-5" role="alert">
                                Taken Lesson
                                </div>
                            </a>
                            <a href={'/admin/report?appId='+organisationId} className='btn btn-link'>
                                <div className="alert alert-info mt-5" role="alert">
                                Report
                                </div>
                            </a>
                            <a href={'/admin/dashboard?appId='+organisationId} className='btn btn-link'>
                                <div className="alert alert-info mt-5" role="alert">
                                Dashboard
                                </div>
                            </a>
                            <a href={'/admin/teacher?appId='+organisationId} className='btn btn-link'>
                                <div className="alert alert-primary mt-5" role="alert">
                                Teacher
                                </div>
                            </a>
                            {
                                isOmega(data.roleId)?
                                    <a href={'/admin/settings?appId='+organisationId} className='btn btn-link'>
                                        <div className="alert alert-primary mt-5" role="alert">
                                        Settings
                                        </div>
                                    </a>
                                :null
                            }                            
                        </div>                        
                        }
                </div>                
                :null
            }   
        </div>
    )
}


export default OmegaPanel