import {FC, useState} from 'react'

import axios from 'axios'
import {Modal, Button} from 'react-bootstrap';
import { Data } from '../../Types/types';
import Messages from '../HelperComponents/Messages';
interface Props
{
    data: Data,
    lessonId:string,
    organisationId:string | null,
    getStudentLessons: Function
}


const DeleteLesson:FC<Props> = ({data, lessonId, organisationId, getStudentLessons}) =>
{    

    const [errors, setErrors] = useState<string[]>([])
    const [messages, setMessages] = useState<string[]>([])

    //modal
    const [isOpen, setIsOpen] = useState<boolean>(false);


    function openModal() {
        setIsOpen(true);
      }
    
    function closeModal() {
        setIsOpen(false);
    }


    const submit = async () => 
    {
        var currentErrors = []

        if(lessonId == null)
        {
            currentErrors.push("lesson not set")            
        }       

        if(organisationId == null)
        {
            currentErrors.push("organisation not set")            
        }  

     

        setErrors(currentErrors)
        if(currentErrors.length > 0)
        {
            return
        }

        await axios.delete(`/api/lesson/${organisationId}/${lessonId}`, data.config)
        .then((response)=>
        {
            closeModal()
            setTimeout(()=>
            {
                getStudentLessons()
                setMessages([])
               
            }, 3000)
        })      
    }
    
    return (
        <div>
            <button className='btn btn-outline-danger' onClick={()=> openModal()}>Delete</button>

            <Modal show={isOpen} onHide={closeModal}>
                <Modal.Header closeButton>
                <Modal.Title>Are you sure you want to delete this lesson?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Messages messages={messages} />
                <Messages messages={errors} />
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={closeModal}>
                    Close
                </Button>
                <Button variant="primary" onClick={submit}>
                    Delete
                </Button>
                </Modal.Footer>
            </Modal>
          
        </div>
    )
}

export default DeleteLesson;