interface Plan 
{
    id: number,
    name: string
}


const InvoicePlans:Plan[] = [
    {id:0, name:"Standard"},
    {id:2, name:"Business"}                  
                     ]

export default InvoicePlans