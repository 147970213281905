interface Code
{
    name: string, 
    message: string
}
const InvoicePlans: Code[] = []

InvoicePlans[0] = {name: "Standard", message: "if you're coming from the business plan, make sure you dont have any un-paid invoices, all unpaid lessons will be marked as paid, this is to ensure invoices no longer get sent for these lessons"}; // standard
InvoicePlans[1] = {name: "Pro", message: "if you're coming from the business plan, make sure you dont have any un-paid invoices"}; //pro
InvoicePlans[2] = {name: "Business", message: ""}; // business

export default InvoicePlans