import  {FC} from 'react'

interface Props
{
    lessons:Lesson[] | null,
    addLesson:Function,
    styling:string,
    saving:boolean,
    isExtraLesson: boolean
}

interface Lesson  
{
    subject:string,
    studentName:string,
    studentId:string,
    groupFee:number,
    groupPay:number,
    singleFee:number,
    singlePay:number
    lessonId:number,
    yearGroup:string,
    subjectId:number

}
const LessonsList:FC<Props> = ({lessons, addLesson, styling, saving, isExtraLesson}) =>
{

    return (
     <div className=''>
<table className={`table ${styling}`}>
        <thead>
            <tr>
                <th>Student</th>
                <th>Subject</th>                
                <th>-</th>
            </tr>
        </thead>
        <tbody>            
            {
                lessons?.map((lesson,index)=>
                (
                    <tr className='table-row'>
                        <td>{lesson.studentName}</td>
                        <td>{lesson.subject}</td>                        
                        <td>
                            <button 
                                disabled={saving}
                                className='btn btn-outline-info'
                                onClick={()=>addLesson(index, isExtraLesson)}
                            >+</button>
                        </td>
                    </tr>
                ))
            }
        </tbody>
        
    </table>
     </div>   
    )
}

export default LessonsList
