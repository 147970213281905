import {FC, useEffect, useState} from 'react'
import axios from 'axios';
import Messages from '../../HelperComponents/Messages'
import { getOrganisationIdFromURL } from '../../HelperComponents/Functions';

interface Props 
{
    data: Data
}

interface Data 
{
    config:Config,
    id:string,
    isAuthenticated:Boolean
}

interface Config
{
    headers:Headers
}

interface Headers
{
    Authorization:string
}

interface Organisation
{
    id: string,
    name: string,
    invoicePlan: number,
    accountId?: string,
    url?: string
}

const OrganisationConnectedAccountPage:FC<Props> = ({data}) => 
{
    const organisationId = getOrganisationIdFromURL();
    const [errors] = useState<string[]>([])
    const [messages] = useState<string[]>([]);
    const [organisations, setOrganisations] = useState<Organisation[]>();
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [accountUrl, setAccountUrl] = useState<string | null>(null);


    const getOrganisations = () =>
    {
        if(organisationId)
        {
            axios.get(`/api/organisation/all`+organisationId, data.config)
            .then((response)=>
            {
                setOrganisations(response.data)
            })
        }        
    }

    useEffect(()=>
    {
        getOrganisations()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(()=>
    {
        getOrganisations()
    },[searchTerm]);

    const createConnectedAccount = (id:string) => 
    {

        axios.get('/api/organisation/createconnectedaccount/'+id, data.config)
        .then((response)=>
        {
            setAccountUrl(response.data);
        })
    }

    const logInToConnectedAccount = (organisationId: string) => 
    {
        axios.get('/api/organisation/getexpresslink/'+organisationId, data.config)
        .then((response)=>
        {
            window.open(response.data);
        })
    }

    return (
        <div className='container'> 
            <h1>Connected Account</h1>

            <Messages messages={errors} />     
            <Messages messages={messages} />

            <div>
            <div className='mt-5'>
                <div className="input-group mb-3">
                    <input onChange={(e:React.FormEvent<HTMLInputElement>)=>setSearchTerm(e.currentTarget.value)} type="text" className="form-control" placeholder="...name ...id" aria-label="name .. id" aria-describedby="basic-addon2"/>
                    <div className="input-group-append">
                        <span className="btn btn-outline-success" id="basic-addon2">Search</span>
                    </div>
                </div>
            </div>
            <table className='table table-responsive mt-3'>
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Name</th>
                        <th>Account Id</th>
                        <th></th>
                        <th>CA</th>
                    </tr>
                </thead>
                <tbody>
                        {organisations?.map((o, index)=>
                        (
                            <tr>
                                <td>{o.id}</td>
                                <td>{o.name}</td>
                                <td>{o.accountId?? "no account id" }</td>
                                <td>{o.url}</td>
                                <td>
                                    {o.accountId?
                                    "exists":
                                    <button onClick={()=>createConnectedAccount(o.id)} className='btn btn-success'>Create</button>
                                    }
                                </td>
                                <td><button onClick={()=>logInToConnectedAccount(o.id)} className='btn btn-primary'>Sign In</button></td>
                                <td><a href={"connectedaccount/payouts?id="+o.id} className='btn btn-outline-info'>Check Payments</a></td>
                            </tr>
                        ))}
                </tbody>
            </table>

            <div className='mt-5'>
                {accountUrl?
                <div>
                    <div className="input-group mb-3">
                    <div className="input-group-append">
                        <span className="btn btn-outline-success" id="basic-addon2">Search</span>
                    </div>
                    <input value={accountUrl} type="text" className="form-control" placeholder="...name ...id" aria-label="name .. id" aria-describedby="basic-addon2"/>
                   
                    </div>
                </div>
                :null}
            </div>
        </div>
        </div>
    )
}

export default OrganisationConnectedAccountPage