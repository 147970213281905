import  {FC,  useEffect} from 'react'
import { getOrganisationIdFromURL } from '../HelperComponents/Functions';
const styles = {
    card: {
      width: "18rem"
    },
  } as const;
interface Props 
{
    data: Data
}

interface Data 
{
    config:Config,
    id:string,
    isAuthenticated:Boolean
}

interface Config
{
    headers:Headers
}

interface Headers
{
    Authorization:string
}



const Dashboard:FC<Props> =  ({data}) =>
{
    const organisationId = getOrganisationIdFromURL();
    useEffect(()=>
    {
      
    },[]);


    return (
    <div className='container'>
        <h1>Dashborad</h1>       
        <div>
            <a href={`omega/organisations?appId=${organisationId}`}>
                <div className="card text-white bg-primary mb-3" style={styles.card}>
                    <div className="card-header"><h5>Organisations</h5></div>
                        <div className="card-body">
                        <p className="card-text">manage organisations</p>
                    </div>
                </div>
            </a>            
        </div>
        
    </div>)
}


export default Dashboard