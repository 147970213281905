import {FC, useState, useEffect} from 'react'
import axios from 'axios'
import {Modal, Button} from 'react-bootstrap';
import Messages from '../HelperComponents/Messages'
import { getOrganisationIdFromURL, validateParentEmail } from '../HelperComponents/Functions';
import DeleteParentButton from './DeleteParentButton';
import EditModelButton from '../Student/EditModelButton';
import {Parent, Props} from '../../Types/types';


interface FormData 
{
    firstName:string, 
    lastName:string,
    email:string,
    organisationId: string
}


const AddEditParent:FC<Props> =  ({data}) =>
{

    const organisationId = getOrganisationIdFromURL();

    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [errors, setErrors] = useState<string[]>([])
    const [messages, setMessages] = useState<string[]>([]);
    const [parents, setParents] = useState<Parent[]>();
    const [isNextPage, setIsNextPage] = useState<boolean | null>(null);
    const [deleteIndex, setDeleteIndex] = useState<number>(-1);
    const [deleteModalIsOpen, setDeleteIsOpen] = useState<boolean>(false);
    const [page, setPage] = useState<number>(1);
    const [showArchived, setShowArchived] = useState<boolean>(false);

    const [searchTerm, setSearchTerm] = useState<string>("");



    const toggleDeleteModal = (index:number) => 
    {
        if(index > -1)
        {
            setDeleteIndex(index);
            openDeletModal()
        }

    }

    const setSearchTermProp = (name: any) => 
    {
     setSearchTerm(name);
     setPage(1);
     getParents(1, showArchived, name);   
    } 

    const toggleShowArchived = () =>
    {
        setPage(1);
        var archived = !showArchived
        setShowArchived(archived)
        getParents(1, archived)
    }

    function openDeletModal() {
        setDeleteIsOpen(true);
      }
    
    function closeDeletModal() {
        setDeleteIsOpen(false);
    }

    const deleteParent = () =>
    {
        if(deleteIndex > -1)
        {
            if(parents != null)
            {
                var parent = parents[deleteIndex];
                
                axios.get(`/api/parent/archive/${organisationId}/${parent.id.toString()}`, data.config)
                
                closeDeletModal();
                setTimeout(() => getParents(), 3000)
                
            }
        }
    }

    const getParents = (p: number | null = null, _showArchived: boolean = false, _searchTerm: string | null = null) =>
    {
        console.log(searchTerm)
        axios.get(`/api/parent/search/${organisationId}/${p??page}/${_showArchived}/${_searchTerm??searchTerm}`, data.config)
        .then((response)=>
        {
            setParents(response.data.parents)
            setIsNextPage(response.data.isNextPage)
        })
    }
    

    const clearForm = () =>
    {
        setFirstName("");
        setLastName("");
        setEmail("");
    }
  
     useEffect(()=>
     {
         getParents()
         // eslint-disable-next-line react-hooks/exhaustive-deps
     },[]);

    const setPagination = (page:number) =>
    {
        setPage(page);
        getParents(page)
    }

    const trimEmail = (email: string) =>
    {
        let str = email.trim().replace(/^\s+|\s+$/gm,'')
        setEmail(str)
    }

    const submit = async () => 
    {
        var currentErrors = []
        if(firstName === null || firstName.length === 0 || firstName === '' ){
            currentErrors.push("Enter First Name")
            
        }

        if(lastName === null || lastName.length === 0 || lastName === ''){
            currentErrors.push("Enter Last Name")
            
        }

        if(email === null || email.length === 0 || email === '' || !validateParentEmail(email)){
            currentErrors.push("Enter Email")            
        }

        if(organisationId === null)        
        {
            currentErrors.push("App id not set")
        }

        setErrors(currentErrors)

        if(currentErrors.length > 0)
        {
            return
        }

        var dataJSON:FormData =
        {
            firstName: firstName,
            lastName: lastName,
            email: email,
            organisationId: organisationId??'0'
        }

        await axios.post('/api/parent',dataJSON, data.config)
        .then((response)=>
        {
            getParents()
            clearForm()
            setMessages(["Parent Saved"])

            setTimeout(()=>
            {
                setMessages([])
               
            }, 3000)
        })
        .catch((error)=>
        {
            let msg = "Error" +  error.response.status + " : " + error.response.data
            alert(msg)         
        })

    }
    

    return (
    <div className='container'>
        <h1>Parent Panel</h1>       
        <Messages messages={errors} />     
        <Messages messages={messages} />
        <div className='mt-3'>
            <h2>New Parent</h2>
            <div className='mt-5'>
                <div className='form-group'>
                    <label className='label'>First Name</label>
                    <input type="text" value={firstName} onChange={(e:React.FormEvent<HTMLInputElement>)=>{setFirstName(e.currentTarget.value)}}></input>
                </div>
                <div className='form-group'>
                    <label className='label'>Last Name</label>
                    <input type="text" value={lastName} onChange={(e:React.FormEvent<HTMLInputElement>)=>{setLastName(e.currentTarget.value)}}></input>
                </div>
                <div className='form-group'>
                    <label className='label'>Email</label>
                    <input placeholder='(Gmail preferred)' type="email" value={email} onChange={(e:React.FormEvent<HTMLInputElement>)=>{trimEmail(e.currentTarget.value)}}></input>
                </div>
                <div className='mt-4'>
                    <button className='btn btn-success' onClick={submit}>Submit</button>
                </div>
            </div>
        </div>
        <hr/>
        <div>
            <div className='mt-3'>
                <div className="input-group mb-3">
                    <input onChange={(e:React.FormEvent<HTMLInputElement>)=>setSearchTermProp(e.currentTarget.value)} type="text" className="form-control" placeholder="...name ...id" aria-label="name .. id" aria-describedby="basic-addon2"/>               
                </div>
            </div>
            <div>
                {page <= 1? null : <button className='btn btn-info mr-3' onClick={()=> setPagination(page <= 1? 1: page -1)}>prev</button>}
                {isNextPage != null && isNextPage? <button className='btn btn-info' onClick={()=> setPagination(page+1)}>next</button> : null}
                {<div className="form-check mt-3">
                    <input checked={showArchived} className="form-check-input" type="checkbox" onClick={()=>toggleShowArchived()} value="" id="flexCheckDefault"/>
                    <label className="form-check-label" >
                            Show Archived
                    </label>
                </div>}
            </div>
            <table className='table table-responsive mt-3'>
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>First name</th>
                        <th>Last name</th>
                        <th>Email</th>
                        <th>S-Id</th>
                        <th>Status</th>
                        <th>-</th>
                        <th>-</th>
                        <th>-</th>
                    </tr>
                </thead>
                <tbody>
                        {parents?.map((parent, index)=>
                        (
                            <tr key={parent.id}>
                                <td>{parent.id}</td>
                                <td>{parent.firstName}</td>
                                <td>{parent.lastName}</td>
                                <td>{parent.email}</td>
                                {/* <td>{parent.stripeCustomerId}</td> */}
                                <td>{parent.status}</td>
                                <td><a href={`/admin/student?appId=${organisationId}&parentId=${parent.id}`} className='btn btn-outline-info'>+ Student</a></td>
                                 <td>
                                    <button 
                                        className='btn btn-outline-danger'
                                        onClick={()=>toggleDeleteModal(index)}
                                        >{parent.status === 'Archived'? 'Un-Archive' : 'Archive'}</button>
                                </td>
                                 <td ><EditModelButton setMessages={setMessages} refreshStudents={getParents} status={parent.status} data={data} id={parent.id} _firstName={parent.firstName} _lastName={parent.lastName} organisationId={organisationId} model={"parent"} /></td>        
                                <td>
                                    {
                                        parent.status === 'Archived'?
                                            <DeleteParentButton parentId={parent.id} data={data}/>
                                        :null
                                    }                                    
                                </td>

                            </tr>
                        ))}
                </tbody>
            </table>
        </div>

        <Modal show={deleteModalIsOpen} onHide={closeDeletModal}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure you want to update this parent status?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {parents && deleteIndex > -1?
                <div>
                    <div>{parents[deleteIndex].firstName}</div>
                    <div>{parents[deleteIndex].lastName}</div>
                </div>                
                :null
            }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeDeletModal}>
            Close
          </Button>
          <Button variant="primary" onClick={deleteParent}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

    </div>)
}


export default AddEditParent