interface BillingData 
{
    company: string,
    bank: string,
    account: string,
    sortCode: string,
    accountNo: string
}

const billingInfo: BillingData =  {
    company: 'MS Learning Circle',
    bank: 'HSBC PLC',
    account: 'MS LEARNING CIRCLE LTD',
    sortCode: '40-07-18',
    accountNo: '71304682'  
  }


  export default billingInfo