import {FC, useEffect, useState} from 'react'
import { getOrganisationIdFromURL } from '../HelperComponents/Functions'
import axios from 'axios'
import StandardInvoicePanel from './Standard/StandardInvoicePanel'
import InvoicePlanId from '../../Enums/InvoicePlanId'
import Dashboard from '../Dashboard/Dashboard'
interface Props 
{
    data: Data
}

interface Data 
{
    config:Config,
    id:string,
    isAuthenticated:Boolean
    roleId?: number | null,
    username?:string| undefined
}

interface Config
{
    headers:Headers
}

interface Headers
{
    Authorization:string
}


const Invoice:FC<Props> = ({data}) => 
{
    const organisationId = getOrganisationIdFromURL();
    const [planId, setPlanId] = useState<number | null>(null);
    const [panel, setPanel] = useState<number | null>(null);
    const [isInvoicedLessons, setIsInvoicedLessons] = useState<boolean | null>(null);


    useEffect(() =>
    {
        if(organisationId != null)
        {
            axios.get(`/api/organisation/check-for-business-plan-lessons/${organisationId}`, data.config)
            .then((response)=>
            {
                setIsInvoicedLessons(response.data)                
            }) 
            
            axios.get(`/api/organisation/current-invoice-plan/${organisationId}`, data.config)
            .then((response)=>
            {
                setPlanId(response.data)
                if(response.data === InvoicePlanId.Standard)
                {
                    setPanel(0);
                }
            }) 

           
        }
         
    },[data.config, organisationId])


    return (

       ((planId === InvoicePlanId.Standard) && isInvoicedLessons) || (planId === InvoicePlanId.Business)?            
        <div className='container'>
            <div className='row mt-4'>
                {/*<div className='col'>
                    <button className='btn btn-outline-info' onClick={()=>setPanel(0)} data-toggle="tooltip" data-placement="right" title="Shows all lessons, invoiced and non-invoiced from both plans"
                    >Standard</button>
                </div>*/}
                <div className='col'>
                <button className='btn btn-outline-info' onClick={()=>setPanel(1)} data-toggle="tooltip" data-placement="right" title="Shows invoices that were generated from the business plan, won't contain info on lessons from old plan"
                >Business</button>
                </div>
            </div>
            {panel === 0?<StandardInvoicePanel data={data}/>:null}
            {panel === 1?<Dashboard data={data}/>:null}
        </div> 
        : (planId === InvoicePlanId.Standard) && !isInvoicedLessons? <StandardInvoicePanel data={data}/> : null
    )
}


export default Invoice