import {FC, useEffect, useState} from 'react'
import { getOrganisationIdFromURL } from '../../HelperComponents/Functions'
import axios from 'axios'
import { DashboardProps, Payout } from '../../../Types/types'

const Payouts:FC<DashboardProps> = ({data}) => 
{
    const organisationId = getOrganisationIdFromURL();
    const [payouts, setPayouts] = useState<Payout[]>([]);

    useEffect(() =>
    {
          
    const getPayouts = () => 
        {
            if(organisationId != null)
            {            
                axios.get(`/api/payout/${organisationId}`, data.config)
                .then((response)=>
                {
                    setPayouts(response.data)
                })            
            }
        } 
        getPayouts();         
    },[data.config, organisationId])
  

    return (
  
        <div className='container dashboard-card'>
         <h2>Payouts</h2>

         <table className='table table-responsive mt-3'>
                <thead>
                    <tr>
                        <th>Id</th>
                        {/* <th>Payout Id</th> */}
                        <th>Payout Date</th>
                        <th>Amount</th>
                        
                        <th>Status</th>
                        <th>-</th>
                    </tr>
                </thead>
                <tbody>
                        {payouts?.map((payout, index)=>
                        (
                            <tr>
                                <td>{payout.id}</td>
                                <td>{payout.payoutDate}</td>
                                <td>{payout.amount}</td>
                                <td>{payout.status}</td>
                            </tr>
                        ))}
                </tbody>
            </table>
          
        </div> 

    )
}


export default Payouts