import  {FC, useState, useEffect} from 'react'
import { useLocation} from 'react-router-dom';
import axios from 'axios'
import DatePicker from "react-datepicker";
import Messages from '../HelperComponents/Messages'
import EditLessonForm from './EditLessonForm';
import {Modal, Button} from 'react-bootstrap';
import { getOrganisationIdFromURL, roundToTwoDecimals, formatDate, moneyValidation, getPriceMSLC } from '../HelperComponents/Functions';
import Days from '../../Enums/Days'
import Organisations from '../../Enums/Organisations';
import DeleteLesson from './DeleteLessonButton';
import ArchiveLessonButton from './ArchiveLessonButton';
import { Props } from '../../Types/types';

interface Student 
{
    id:number
    firstName:string,
    lastName:string,
    status:string
}

interface Teacher 
{
    id:string,
    firstName:string,
    lastName:string
}

interface Subject 
{
    id: number,
    subject:string
}

interface YearGroup 
{
    id: number,
    yearGroup:string
}

interface FormData 
{
    studentId:string | null,
    teacherId:string | null,
    subject:number | null,
    yearGroup:number | null,
    singleFee:string | null,
    singlePay:string | null,
    groupFee:string | null,
    groupPay:string | null,
    startDate:Date | null,
    endDate:Date | null,
    organisationId: string | null,
    day: number | null,
    lessonType: number
    
}

interface Lesson 
{
    id:string,
    studentName: string,
    teacherName:string,
    subject:string | null,
    yearGroup:string | null,
    singleFee:number | null,
    singlePay:number | null,
    groupFee:number | null,
    groupPay:number | null,
    startDate:Date,
    endDate:Date,
    day:string | null,
    status:string | null
    organisationId: string | null,
    lessonType:number
}



const AddEditLesson:FC<Props> = ({data}) =>
{
    const [studentId, setStudentId] = useState<string|null>(null);
    const [student, setStudent] = useState<Student|null>(null);
    const [teachers, setTeachers] = useState<Teacher[]|null>(null);
    const [subjects, setSubjects] = useState<Subject[]| null>(null);
    const [yeargroups, setYearGroups] = useState<YearGroup[]| null>(null);
    const [lessons, setLessons] = useState<Lesson[]|null>([]);

    const [teacherId, setTeacherId] = useState<string|null>(null);
    const [subjectId, setSubjectId] = useState<string|null>(null);
    const [yearGroupId, setYearGroupId] = useState<string|null>(null);
    const [lessonGroupTypeId, setLessonGroupTypeId] = useState<string|null>("0");
    var [singleFee, setSingleFee] = useState<string|null>("0");
    var [singlePay, setSinglePay] = useState<string|null>("0");
    var [groupFee, setGroupFee] = useState<string|null>(null);
    var [groupPay, setGroupPay] = useState<string|null>(null);
    const [startDate, setStartDate] = useState<Date|null>(null);
    const [endDate, setEndDate] = useState<Date|null>(null);
    const [day, setDay] = useState<number| null>(null);

    const [lessonPrice, setLessonPrice] = useState<number| null>(null);  
    
    const [lessonCommission, setLessonCommission] = useState<string| null>(null);  

    const [errors, setErrors] = useState<string[]>([])
    const [messages, setMessages] = useState<string[]>([])

    //organisation
    const organisationId = getOrganisationIdFromURL();
    const isLegacyClient = organisationId === Organisations.MSLC;    

    //modal
    const [selectedLessonIndex, setSelectedLessonIndex] = useState<number>(-1);
    const [isOpen, setIsOpen] = useState<boolean>(false);


    const search = useLocation().search;
    const id:string|null = new URLSearchParams(search).get('studentId')


    useEffect(()=>
    {       
        if(id !== null)
        {
            setStudentId(id)
            getStudent(id)
            //getStudentLessons(id)
            
            
        } 
        getTeachers();  
        getSubjects();
        getYearGroups();   
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[studentId])


    useEffect(()=>
    {
       updateLessonPrice()
       // eslint-disable-next-line react-hooks/exhaustive-deps
    },[yearGroupId, subjectId, lessonGroupTypeId])

    const updateLessonPrice = () =>
    {
        let yearGroup = yeargroups?.find(x => x.id.toString() === yearGroupId);
        let lessonType = lessonGroupTypeId?.toString() === "0" ? "G" : "O";
        let subject = subjects?.find(x => x.id.toString() === subjectId); 
        let price = getPriceMSLC(yearGroup?.yearGroup??'', subject?.subject??'', lessonType);
        let commission = getPriceMSLC(yearGroup?.yearGroup??'', subject?.subject??'', lessonType, true);   
        if((price === null || price === undefined) && yearGroupId == null && subjectId == null && lessonGroupTypeId == null )
        {
            alert("Pricing error")
            return
        }            
        
        setLessonPrice(price);
        setLessonCommission(commission);
    } 

    function openModal(index:number) {
        setSelectedLessonIndex(index);
        setIsOpen(true);
      }
    
    function closeModal() {
        setIsOpen(false);
        getStudentLessons()
    }

    const getStudentLessons = () => 
    {
        if(studentId !== null && organisationId !== null)
        {
            axios.get('/api/lesson/student/'+studentId.toString()+"/"+organisationId?.toString(), data.config)
         .then((response)=>
         {
             setLessons(response.data);
         })
        }
        else
        {
            setErrors(["no studen or organisation set"])
            setTimeout(()=>{
                setErrors([])
            },3000)
        }        
    }

    const getStudent = (id:string) =>
    {  
        setStudentId(id);
        
         axios.get('/api/student/id/'+id.toString()+"/"+organisationId?.toString(), data.config)
         .then((response)=>
         {
             setStudent(response.data);
         })
    }

    const getTeachers = () =>
    {
         axios.get('/api/teachers/'+organisationId?.toString(), data.config)
         .then((response)=>
         {            
             setTeachers(response.data);
         })
    }

    const getSubjects = () =>
    {
         axios.get(`/api/lesson/subjects/${organisationId}`, data.config)
         .then((response)=>
         {            
             setSubjects(response.data);
         })
    }

    const getYearGroups = () =>
    {
         axios.get(`/api/lesson/yeargroups/${organisationId}`, data.config)
         .then((response)=>
         {            
             setYearGroups(response.data);
         })
    }

    const clearForm = () =>
    {
        setTeacherId("");
        setSubjectId("");
        setDay(0);
        setYearGroupId("");
        setSingleFee(null);
        setSinglePay(null);
        setGroupFee(null);
        setGroupPay(null);
        setStartDate(null);
        setEndDate(null);
    }

    const submit = async () => 
    {
      
        var currentErrors = []
        if(studentId === null || studentId.length === 0)
        {
            currentErrors.push("Invalid Student Id")
        }

        if(student === null || student?.id === null)
        {
            currentErrors.push("Student does not exist")
        }

        if(teacherId === null || teacherId.length === 0)
        {
            currentErrors.push("Teacher not chosen")
        }

        if(subjectId === null || subjectId.length === 0)
        {
            currentErrors.push("Subject not chosen")
        }

        if(yearGroupId === null || yearGroupId.length === 0)
        {
            currentErrors.push("Year group not chosen")
        }

        if(singleFee === null|| isNaN(Number(singleFee)))
        {
            currentErrors.push(isLegacyClient?"Please enter fee adj": "Please enter single fee")
        }

        if(singlePay === null || isNaN(Number(singlePay)))
        {
            currentErrors.push(isLegacyClient?"Please enter pay adj": "Please enter single pay")
        }

        if(isLegacyClient)
        {
            let yearGroup = yeargroups?.find(x => x.id.toString() === yearGroupId);
            let lessonType = lessonGroupTypeId?.toString() === "0" ? "G" : "O";
            let subject = subjects?.find(x => x.id.toString() === subjectId);

            let price = getPriceMSLC(yearGroup?.yearGroup??'', subject?.subject??'', lessonType);     
            
            if(price === null || price === undefined)
            {
                currentErrors.push("Pricing error")
            }
            
            setLessonPrice(price);
            let feeAdj = moneyValidation(singleFee)
            let payAdj = moneyValidation(singlePay)

            if(price === null || price === undefined)
            {
                currentErrors.push("price issue")
            }
            if(lessonGroupTypeId === null)
            {
                currentErrors.push("Please enter lesson group type")
            }
            
            singleFee = moneyValidation(singleFee).toString();
            singlePay = moneyValidation(singlePay).toString();

            groupFee = moneyValidation(price + feeAdj).toString();
            groupPay = roundToTwoDecimals((getPriceMSLC(yearGroup?.yearGroup??'', subject?.subject??'', lessonType, true))+payAdj).toString()

        }

        if(groupFee === null)
        {
            currentErrors.push("Please enter group fee")
        }

        if(groupPay === null)
        {
            currentErrors.push("Please enter group pay")
        }

        if(startDate === null)
        {
            currentErrors.push("Please enter start date")
        }

        if(endDate === null)
        {
            currentErrors.push("Please enter end date")
        }

        if(organisationId === null)
        {
            currentErrors.push("organisation not set")            
        }

        if(day === null)
        {
            currentErrors.push("Day not set")       
        }      

        var dataJSON:FormData =
        {
            studentId: studentId,
            teacherId: teacherId,
            subject:parseInt(subjectId??"0"),
            yearGroup:parseInt(yearGroupId??"0"),
            singleFee:singleFee,
            singlePay:singlePay,
            groupFee:groupFee,
            groupPay:groupPay,
            startDate:startDate,
            endDate:endDate,
            organisationId: organisationId,
            day:day,
            lessonType: parseInt(lessonGroupTypeId??"0")
        }
        
        setErrors(currentErrors)
        if(currentErrors.length > 0)
        {
            return
        }


        await axios.post('/api/lesson/add',dataJSON, data.config)
        .then((response)=>
        {
            //getParents()
            clearForm()
            setMessages(["Lesson Saved"])

            setTimeout(()=>
            {
                setMessages([])
               
            }, 3000)
        })      
    }
    
    return (
        <div className='container'>
            <h1>Lesson Panel</h1>
            <Messages messages={errors} />     
            <Messages messages={messages} />
            <div className='mt-3 form-group'>
                <label>Student Id</label>
                <input type="text" value={studentId?.toString()} onChange={(e:React.FormEvent<HTMLInputElement>) => getStudent(e.currentTarget.value)}></input>
            </div>
            {
                student?.id !== null && organisationId !== null && organisationId !== undefined?
                (
                <div className='mt-5'>
                    <div className='form-group'>
                        <label className='label'>Parent</label>
                        <div><strong>{studentId} - {student?.firstName} - {student?.lastName}</strong></div>
                    </div>
                    <div className='form-group'>
                        <label className='label'>Student Id</label>
                        <div>{studentId}</div>
                    </div>
                    <div className='form-group'>
                        <label className='label'>Teacher</label>
                        <select value={teacherId?.toString()} onChange={(e:React.FormEvent<HTMLSelectElement>)=>setTeacherId(e.currentTarget.value) }>
                        <option value={""}>None</option>
                            {teachers?.map((teacher, index)=>
                            (
                                <option value={teacher.id}>{teacher.firstName}</option>
                            ))}                            
                        </select>
                    </div>
                    <div className='form-group'>
                        <label className='label'>Subject</label>
                        <select value={subjectId?.toString()} onChange={(e:React.FormEvent<HTMLSelectElement>)=>setSubjectId(e.currentTarget.value) }>
                            <option value={""}>None</option>
                            {subjects?.map((subject, index)=>
                            (
                                <option value={subject.id}>{subject.subject}</option>
                            ))}                            
                        </select>
                    </div>
                    <div className='form-group'>
                        <label className='label'>Year Group</label>
                        <select value={yearGroupId?.toString()} onChange={(e:React.FormEvent<HTMLSelectElement>)=>setYearGroupId(e.currentTarget.value) }>
                            <option value={""}>None</option>
                            {yeargroups?.map((yearGroup, index)=>
                            (
                                <option value={yearGroup.id}>{yearGroup.yearGroup}</option>
                            ))}                            
                        </select>
                    </div>
                    <div className='form-group'>
                        <label className='label'>Day</label>
                        <select value={day??""} onChange={(e:React.FormEvent<HTMLSelectElement>)=>setDay(parseInt(e.currentTarget.value)) }>
                            <option value={""}>None</option>
                            {Days?.map((day, index)=>
                            (
                                <option value={index}>{day}</option>
                            ))}                            
                        </select>
                    </div>
                    {
                        isLegacyClient?
                            <div className='form-group'>
                                <label className='label'>Lesson Group Type</label>
                                <select onChange={(e:React.FormEvent<HTMLSelectElement>)=>setLessonGroupTypeId(e.currentTarget.value) }>
                                
                                    <option value={0}>{"Group"}</option>
                                    <option value={1}>{"1 to 1"}</option>                           
                                </select>
                            </div>
                        : null
                    }
                    
                    {
                        
                    <div>
                        {
                            isLegacyClient? 
                                <div className='mb-3'>
                                    <div>Fee: £{lessonPrice??0}</div>
                                    <div>Pay: £{lessonCommission}</div>
                                </div>  
                            :
                            <div>                               
                                <div className='form-group'>
                                    <label className='label'>Group Fee</label>
                                    <input value={groupFee?groupFee:""}  onChange={(e:React.FormEvent<HTMLInputElement>)=>setGroupFee((e.currentTarget.value))}  type="number"/>
                                </div>
                                <div className='form-group'>
                                    <label className='label'>Group Pay</label>
                                    <input value={groupPay?groupPay:""} onChange={(e:React.FormEvent<HTMLInputElement>)=>setGroupPay((e.currentTarget.value))} type="number" />
                                </div>
                            </div> 
                        }

                        <div className='form-group'>
                            <label className='label'>{isLegacyClient? "Fee Adjustment" : "Single Fee"}</label>
                            <input value={singleFee?singleFee:""}   onChange={(e:React.FormEvent<HTMLInputElement>)=>setSingleFee((e.currentTarget.value))} type='number'/>
                        </div>
                        <div className='form-group'>
                            <label className='label'>{isLegacyClient? "Pay Adjustment" : "Single Pay"}</label>
                            <input value={singlePay?singlePay:""}  onChange={(e:React.FormEvent<HTMLInputElement>)=>setSinglePay((e.currentTarget.value))} />
                        </div>
                        
                        
                    </div>                            
                        
                    }
{/* 
                    <div className='form-group'>
                        <label className='label'>{isLegacyClient? "Group Fee" :"Fee Adjustment"}</label>
                        <input value={feeAdjustment?feeAdjustment:""}  onChange={(e:React.FormEvent<HTMLInputElement>)=>setFeeAdjustment((e.currentTarget.value))} type="number"  />
                    </div>
                    <div className='form-group'>
                        <label className='label'>{isLegacyClient? "Group Pay" : "Pay Adjustment"}</label>
                        <input value={payAdjustment? payAdjustment:""} onChange={(e:React.FormEvent<HTMLInputElement>)=>setPayAdjustment((e.currentTarget.value))} type="number" />
                    </div>
                     */}
                    
                    <div className='form-group'>
                        <label className='label'>Start Date</label>
                        <DatePicker 
                            placeholderText='Select date' 
                            dateFormat={'dd/MM/yyyy'}
                            onChange={date => setStartDate(date)}
                            selected={startDate}/>
                    </div>
                    <div className='form-group'>
                        <label className='label'>End Date</label>
                        <DatePicker 
                            placeholderText='Select date' 
                            dateFormat={'dd/MM/yyyy'}
                            onChange={date => setEndDate(date)}
                            selected={endDate}/>
                    </div>
                    <div className='mt-2'>
                        <button className='btn btn-success' onClick={submit}>submit</button>
                    </div>


                    <hr/>
                   
                    
                </div>
                )
                :
                (
                    <div>No student set!</div>
                )
            }

            {
                student && studentId !== null?
                (
                    <div className='mt-3'>
                    <   button className='btn btn-outline-success' onClick={()=>getStudentLessons()}>Get Lessons</button>
                    </div>
                )
                :null
            }

           
            <hr/>
            <div className='mt-3'>
                 <h3 className="mt-3 mb-3">Lesson Details</h3>
                 {
                    lessons && lessons.length > 0?
                    (
                        <table className='table table-responsive'>
                            <thead>
                                <tr>
                                    <td>Student</td>
                                    <td>Teacher</td>
                                    <td>Subject</td>
                                    <td>Year Group</td>
                                    <td>{isLegacyClient? "Fee Adj": "SF"}</td>
                                    <td>{isLegacyClient? "Pay Adj": "SP"}</td>
                                    <td>{isLegacyClient? "Fee" : "GF"}</td>
                                    <td>{isLegacyClient? "Pay" : "GP"}</td>
                                    <td>Start date</td>
                                    <td>End date</td>
                                    <td>Day</td>
                                    <td>-</td>
                                    <td>-</td>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    lessons?.map((lesson, index)=>
                                    (
                                        <tr>
                                            <td>{lesson.studentName}</td>
                                            <td>{lesson.teacherName}</td>
                                            <td>{lesson.subject}</td>
                                            <td>{lesson.yearGroup}</td>
                                            <td>{lesson.singleFee}</td>
                                            <td>{lesson.singlePay}</td>
                                            <td>{lesson.groupFee}</td>
                                            <td>{lesson.groupPay}</td>
                                            <td>{formatDate(lesson.startDate)}</td>
                                            <td>{formatDate(lesson.endDate)}</td>
                                            <td>{lesson.day}</td>
                                            <td>{lesson.status}</td>
                                            <td><button className='btn btn-outline-info' onClick={()=> openModal(index)}>Edit</button></td>
                                            <td><DeleteLesson data={data} lessonId={lesson.id} organisationId={organisationId} getStudentLessons={getStudentLessons}/></td>
                                            <td><ArchiveLessonButton setMessages={setMessages} data={data} status={lesson.status} lessonId={lesson.id} organisationId={organisationId} getStudentLessons={getStudentLessons}/></td>
                                            
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                            
                    )
                    :(<div>No Lessons!</div>)
                 }
            </div>

            <Modal show={isOpen} onHide={closeModal}>
                <Modal.Header closeButton>
                <Modal.Title>Edit Lesson</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {lessons && student && studentId && selectedLessonIndex > -1?
                        <EditLessonForm 
                            studentId={studentId} 
                            lessonId={lessons[selectedLessonIndex].id} 
                            data={data}
                            student={student}
                            isLegacyClient={isLegacyClient}
                            closeModal={closeModal}/>              
                        :null
                    }
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={closeModal}>
                    Close
                </Button>
                </Modal.Footer>
            </Modal>
          
        </div>
    )
}

export default AddEditLesson;