import { useAuth0 } from "@auth0/auth0-react";


const LoginButton = () => {
  const { loginWithRedirect ,isAuthenticated } = useAuth0();
if(isAuthenticated){


  //setToken(user?.nickname?.token)

  return  null
}
  return <button className="btn btn-outline-info" onClick={() => loginWithRedirect()}>Log In</button>;
};

// async function  setToken (token:string)  {
  
//   localStorage.setItem('token', token);

//   if(token.length === 0){
//     alert('intruder reported')
//   }
  
// }

export default LoginButton;