import {FC} from 'react'
import { Form } from 'react-bootstrap';
import LessonCodes from '../Enums/LessonCode';

interface LessonType 
{
    id: number,
    type: string
}
interface Props
{
    takenLessons:TakenLesson[] | null,
    updateHours:Function,
    removeTakenLesson:Function,
    toggleLesson:Function,
    updateLessonType: Function,
    updateLessonCode: Function,
    lessonTypes:LessonType[],
    isProPlan: boolean
}


interface TakenLesson  
{  
    id:number|null,
    studentId:string,
    teacherId: string,
    subjectId:number,
    singleFee:number,
    groupFee:number,
    singlePay:number,
    groupPay:number,
    totalFee:number,
    totalPay:number,
    studentName:string,
    hours:number,
    lessonType:number,
    lessonDate:string,
    takenLessonPaidStatus:number | null,
    takenLessonRefundStatus:number | null,
    organisationId:number,
    invoicePlanId: number,
    subjectText: string,
    stripeRefundId:string | null,
    invoiceId: number | null,
    lessonCode:number,
    isTaken: boolean,
    takenLessonPaidStatusText: string
}
const TakenLessonsList:FC<Props> = ({takenLessons, updateHours, removeTakenLesson, toggleLesson, updateLessonType, updateLessonCode, lessonTypes, isProPlan}) =>
{

    return (
    <table className='table table-responsive table-borderless register-table'>
        <thead>
            <tr>
                {isProPlan? <th>Status</th> : null}
                <th>Subject</th>
                <th>Student</th>
                <th>Type</th>
                <th>-</th>
                <th>hours</th>
                <th>-</th>
                {isProPlan? <th>-</th> : null}
            </tr>
        </thead>
        <tbody>            
            {
                takenLessons?.map((takenLesson,index)=>
                (
                    <tr>
                        {
                            isProPlan?
                                <td>
                                    {
                                        takenLesson.invoiceId != null && takenLesson.stripeRefundId === null? <span className="badge badge-secondary">{"invoiced"}</span> :null
                                    }
                                    {
                                        takenLesson.stripeRefundId != null? <span className="badge badge-danger">{"refunded"}</span> :null
                                    }
                                    {
                                        takenLesson.stripeRefundId === null && takenLesson.invoiceId === null? <span className="badge badge-primary">{"not invoiced"}</span> :null
                                    }
                                    {
                                        takenLesson.takenLessonPaidStatus === 2 && takenLesson.invoiceId != null? <span className="ml-1 badge badge-success">{"paid"}</span> :null
                                    }
                                </td>
                            :null
                        }
                        
                        <td>{takenLesson.subjectText}</td>
                        <td>{takenLesson.studentName}</td>
                        <td>
                            <div className="form-group table-col">                                        
                                <select className="form-control" defaultValue={takenLesson.lessonType} onChange={(e)=>updateLessonType(index, e.target.value)}>
                                    {lessonTypes.map((type, index) =>
                                    (
                                        <option 
                                        key={takenLesson.id?.toString()+index.toString()} 
                                        value={type.id}>{type.type.charAt(0)}</option>
                                    ))
                                    }
                                </select>       
                            </div>
                        </td>
                        <td>
                            <div className="form-group table-col">                                        
                                <select className="form-control" defaultValue={takenLesson.lessonCode} onChange={(e)=>updateLessonCode(index, e.target.value)}>
                                    {LessonCodes.map((type, index) =>
                                    (
                                        <option 
                                         key={takenLesson.id?.toString()+type.name} 
                                         value={type.id}>{type.name.charAt(0)}</option>
                                    ))
                                    }
                                </select>       
                            </div>
                        </td>
                        <td>
                            <input 
                                onChange={(e) => updateHours(index, e.target.value)}
                                defaultValue={takenLesson.hours} 
                                type="number" 
                                className='form-control table-col'
                                disabled={takenLesson.invoiceId != null}
                                ></input>
                        </td>
                        <td>
                            <button 
                                className='btn btn-outline-danger'
                                onClick={(e) => removeTakenLesson(index)}
                                disabled={takenLesson.invoiceId != null}
                            >x</button>
                        </td>  

                        {
                            isProPlan?
                                <td>
                                    <div className='mt-1 switch'>
                                        <Form.Switch
                                            onClick={(()=> toggleLesson(index))}                             
                                            type={"switch"}
                                            id={`switch-${index}`}
                                            defaultChecked={takenLesson.isTaken}
                                            size={100}
                                            width={100}
                                        />
                                    </div>                        
                                </td>      
                            : null
                        }                                                                
                    </tr>
                ))
            }
        </tbody>
        
    </table>)
}

export default TakenLessonsList
