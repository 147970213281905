import {FC, useEffect, useState} from 'react'
import { formatDate, getOrganisationIdFromURL } from '../HelperComponents/Functions'
import axios from 'axios'
import { DashboardProps, AccountInfo, AccountStats } from '../../Types/types'
import AccountInfoTable from './AccountInfoTable'
import DatePicker from "react-datepicker";

const AccountInfoPanel:FC<DashboardProps> = ({data}) => 
{
    const organisationId = getOrganisationIdFromURL();
    const [accountInfo, setAccountInfo] = useState<AccountInfo | null>(null);
    const [accountStats, setAccountStats] = useState<AccountStats | null>(null);
    const [fromDate, setFromDate] = useState<Date | null>(null);
    const [toDate, setToDate] = useState<Date | null>(null);


    useEffect(()=>
    {      
        if(organisationId != null){
            axios.get(`/api/organisation/details/${organisationId}`, data.config)
            .then(response=>{
                var data = response.data
                setAccountInfo(data);
            })
        }
        
    },[data.config, organisationId])

    const setDate = (date:Date, isFromDate = false) => {
       let _fromDate = null;
       let _toDate = null;

        if(isFromDate){
            setFromDate(date)
            _fromDate = date;
        } else {
            setToDate(date);
            _toDate = date;
        }

        _fromDate = fromDate??_fromDate;
        _toDate = toDate??_toDate
        if(_fromDate == null || _toDate == null || (_toDate <= _fromDate))
        {
            return
        }
        
        axios.get(`/api/organisation/report/${organisationId}/${formatDate(_fromDate)}/${formatDate(_toDate)}`, data.config)
            .then(response=>{
                var data = response.data
                setAccountStats(data)
            })
    }

    return (
  
        <div className='container card-body dashboard-card mb-3'>
            <h2 className='mb-5'>Account</h2>
            <div className='row mb-3'>
                    <div className='col'>
                        <DatePicker 
                                placeholderText='from...' 
                                dateFormat={'dd/MM/yyyy'}
                                onChange={(date:Date) => setDate(date, true)}      
                                selected={fromDate}/>
                    </div>
                    <div className='col'>
                        <DatePicker 
                                placeholderText='to...' 
                                dateFormat={'dd/MM/yyyy'}
                                onChange={(date:Date) => setDate(date)}
                                selected={toDate}/>
                    </div>                
                </div> 
            <div className='row mt-5'>
                <div className='col-4'>
                   <AccountInfoTable data={data}balanceList={accountInfo?.availableBalances} title='Available balances'/>
                </div>   
                <div className='col-4'>
                   <AccountInfoTable data={data}balanceList={accountInfo?.pendingBalances} title='Pending balances' />
                </div>    
                <div className='col-4'>
                   <AccountInfoTable data={data}balanceList={accountInfo?.connectedReservedBalances} title='Connected Reversed balances' />
                </div>              
            </div>
            <hr/>
            <div className='mt-5'>
                               
                    {
                     accountStats?
                     <div className='row'>
                     <div className='col'>
                        <div className="card text-white bg-info mb-3 m-5" style={{minWidth: '9rem'}}>
                            <div className="card-header">Revenue</div>
                            <div className="card-body">
                                <h5 className="card-title">£{accountStats?.revenue}</h5>
                            </div>
                        </div>
                    </div>
                    <div className='col'>
                        <div className="card text-white bg-info mb-3 m-5" style={{minWidth: '18rem'}}>
                            <div className="card-header">NET Revenue</div>
                            <div className="card-body">
                                <h5 className="card-title">£{accountStats?.netGrossRevenue}</h5>
                            </div>
                        </div>
                    </div>
                    </div>
                     :null   
                    }
                    
                                   
               
            </div>            
            <hr/>
        </div> 


    )
}


export default AccountInfoPanel