import {FC, useEffect} from 'react'
import { getOrganisationIdFromURL, isAdmin } from './HelperComponents/Functions'
import ProgressBar from 'react-bootstrap/ProgressBar';
interface Props 
{
    data: Data
}

interface Data 
{
    config:Config,
    id:string,
    isAuthenticated:Boolean,
    roleId: number | null
}

interface Config
{
    headers:Headers
}

interface Headers
{
    Authorization:string
}

const AdminPanel:FC<Props> = ({data}) => 
{
    const organisationId = getOrganisationIdFromURL();


    useEffect(()=>
    {

    },[data.roleId])

    

    return (
        <div className='container mb-5'>
            {
                isAdmin(data.roleId)?
                <div>
                        <div className='mt-5'>
                            <ProgressBar aria-disabled={true} variant='info'  now={100} label={``} />
                        </div>  
                    <div className='mb-5'>
                    <a href={'/admin/parent?appId='+organisationId} className='btn btn-link'>
                        <div className="alert alert-primary mt-5" role="alert">
                        Parent
                        </div>
                    </a>
                    
                    <a href={'/admin/student?appId='+organisationId} className='btn btn-link'>
                        <div className="alert alert-danger mt-5" role="alert">
                        Student
                        </div>
                    </a>
           
                       <a href={'/admin/invoice-panel?appId='+organisationId} className='btn btn-link'>
                        <div className="alert alert-primary mt-5" role="alert">
                        Invoice
                        </div>
                    </a> 
                    </div>                    

                    
                                           
                  
                </div>                
                :null
            }   
        </div>
    )
}


export default AdminPanel