
import {FC, useEffect,  useState} from 'react'
import DatePicker from "react-datepicker";
import Messages from '../HelperComponents/Messages'
import axios from 'axios'
import {formatDate, roundPay} from '../HelperComponents/Functions'
import { getOrganisationIdFromURL, isOmega } from '../HelperComponents/Functions';

interface Props 
{
    data: Data
}

interface Data 
{
    config:Config,
    id:string,
    isAuthenticated:Boolean,
    roleId: number | null
}

interface Config
{
    headers:Headers
}

interface Headers
{
    Authorization:string
}

interface TakenLesson 
{
    id: number,
    subject:string,
    student:string,
    teacher:string,
    totalPay:number,
    totalFee:number,
    lessonDate:Date,
    invoiceId:number,
    stripeInvoiceId:string,
    stripeRefundId:string,
    status:string,
    hours:number

}


const MyLessons:FC<Props> = ({data}) => 
{
    const organisationId = getOrganisationIdFromURL();
    const [fromDate , setFromDate] = useState<Date | null>(new Date());
    const [toDate , setToDate] = useState<Date | null>(new Date());    
    const [lessons , setLessons] = useState<TakenLesson[]>([]);
    const [roleId, setRoleId] = useState<number | null>(null);
    
    const [errors] = useState<string[]>([])
    const [messages] = useState<string[]>([])

    useEffect(() => 
    {      
     setDataId(data.id)  
     setRoleId(data.roleId)
     setDates()
      //getStudents();
      //getTeacherById();
    },[data.id]) 

    const setDates = () =>
    {
        var today = new Date();
        var year = today.getFullYear();
        var month = today.getMonth();
        var prevMonth = month - 1
        var prevYear = year
        if(month === 0)
        {
            prevMonth = 11;
            prevYear = year - 1;
        }

        var fromDate = new Date(prevYear, prevMonth, 26)
        var toDate = new Date(year, month, 25)

        setFromDate(fromDate);
        setToDate(toDate);
    }

    const [dataId, setDataId] = useState<string>('');
    const [pay, setPay] = useState<number| null>(null);

    const setDataIdProp = (id:string) => 
        {
            if(id.length === 0 || id.length === null)
                {
                    setDataId(data.id)
                }
                else
                {
                    setDataId(id)
                }
        }
  
    const submit = async (pageNumber:number) => 
    {     
        if(fromDate === null)
        {
            alert("date error")
            return
        }

        if(toDate === null)
        {alert("date error")
            return
        }

        if(organisationId === null || organisationId === undefined || organisationId === '')
        {
            alert("app error")
            return
        }

        let url = '/api/taken-lesson/history?teacherId='+ dataId.toString() 
        +'&fromDate=' + formatDate(fromDate) 
        + '&toDate=' + formatDate(toDate) 
        + '&organisationId=' + organisationId;

       
        axios.get(url, data.config)
             .then(response=>{
                  var data = response.data                        
                  setLessons(data)
                  calculatePay(data)
             })

    }
    const calculatePay = async (lessons:TakenLesson[]) => 
    {
        var pay = 0;
        lessons.map((lesson)=>
        {
             pay += lesson.totalPay
             return pay;
        })

        setPay(roundPay(pay))
    }    


    return (
    <div className='container'>
        <Messages messages={errors} />     
        <Messages messages={messages} />

        <div  className='mt-3'>
            <div className="row">
                <div className='col'>
                    <div className='form-group'>
                        <label className='label text-left ml-3'>From date</label>
                        <div>
                            <DatePicker 
                                    placeholderText='Select date' 
                                    dateFormat={'dd/MM/yyyy'}
                                    onChange={date => setFromDate(date)}
                                    selected={fromDate}/>
                        </div>
                    </div>
                    <div className='form-group'>
                        <label className='label text-left ml-3'>To date</label>
                        <div>
                            <DatePicker 
                                    placeholderText='Select date' 
                                    dateFormat={'dd/MM/yyyy'}
                                    onChange={date => setToDate(date)}
                                    selected={toDate}/>
                        </div>
                    </div>
                    <div className='mb-3'>
                        <button onClick={()=>submit(1)} className='btn btn-outline-success'>Search</button>
                       
                    </div>  
                </div>
               
            </div>
        </div>

        {
        isOmega(roleId) === true?
            <div className='mt-1 mb-3'>
                <label>Teacher ID : </label>
                <input className='input' onChange={(e)=>setDataIdProp(e.target.value)}></input>
            </div>
        :null
    }

    {
        pay?
        <div>
            <button  className='btn btn-outline-info'>{"£ "+pay}</button>
        </div>
        :null
    }

        <div className='mt-5'>
            <table  className='table table-responsive'>
                <thead>
                    <tr>
                        {/* <td>Id</td> */}
                        <td>Date</td>
                        <td>Student</td>
                        <td>Subject</td>
                        {/* <td>Teacher</td> */}
                        
                        <td>Hours</td>
                        {/* <td>Total Fee</td> */}
                        <td>Total Pay</td>
                        
                        <td>-</td>
                    </tr>
                </thead>
               
                    <tbody>
                        {
                            lessons?.map((lesson, index)=>
                            (
                                <tr>
                                    {/* <td>{lesson.id}</td> */}
                                    <td>{formatDate(lesson.lessonDate)}</td>
                                    <td>{lesson.student}</td>
                                    <td>{lesson.subject}</td>
                                    {/* <td>{lesson.teacher}</td> */}
                                    
                                    <td>{lesson.hours}</td>
                                    {/* <td>{lesson.totalFee}</td> */}
                                    <td>{lesson.totalPay}</td>
                                    
                                    {/* <td>{lesson.status}</td> */}
                                    {/* <td>{lesson.invoiceId}</td> */}
                                    {/* <td>{lesson.stripeInvoiceId}</td> */}
                                    {/* <td>{lesson.stripeRefundId}</td> */}
                                </tr>
                            ))
                        }
                    </tbody>
                          
            </table>
        </div>      
    </div>)
}


export default MyLessons