interface LessonCode 
{
    id: number,
    name: string
}


const LessonCodes:LessonCode[] = [
    {id:0, name:"Standard"},
    {id:1, name:"Tial"},
    {id:2, name:"Extra"},
    {id:3, name:"Missed"},
    {id:4, name:"Informed"},
    {id:5, name:"Cover"}                      
                     ]

export default LessonCodes
