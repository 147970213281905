import {FC, useState, useEffect} from 'react'
import axios from 'axios'
import Messages from '../../HelperComponents/Messages'
import { validateEmail } from '../../HelperComponents/Functions'

interface Props 
{
    organisation: Organisation | null,
    data:Data,
    closeModal: Function   
}

interface Role {
    id: number,
    name: string
}

interface Data 
{
    config:Config,
    id:string,
    isAuthenticated:Boolean
}

interface Organisation
{
  id: number,
  name: string
}

interface Config
{
    headers:Headers
}

interface Headers
{
    Authorization:string
}

interface FormData 
{
    organisationId:number | undefined,
    roleId:number | null,
    email:string | null,
    firstName:string | null,
    lastName:string | null,
    userId: string | null
     
}

const AddUserToOrganisationFrom:FC<Props> = ({organisation, data, closeModal}) => 
{    
    const [roleId, setRoleId] = useState<number | null>(1);
    const [email, setEmail] = useState<string|null>(null);
    const [firstName, setFirstName] = useState<string|null>(null);
    const [lastName, setLastName] = useState<string|null>(null);  
    const [userId, setUserId] = useState<string|null>(null);    
    const [roles, setRoles] = useState<Role[]>([]);
    const [errors, setErrors] = useState<string[]>([])
    const [messages, setMessages] = useState<string[]>([])
    const [_organisation, setOrganisation] = useState<Organisation | null>(null)
    const [isExistingTeacher, setIsExistingTeacher] = useState<boolean>(true)
    const [isUserPartOfOrganisation, setIsUserPartOfOrganisation] = useState<boolean>(true)

   

    useEffect(()=>    
    {
        setOrganisation(organisation) 
        getRoles(organisation?.id)
        //getLesson(lessonId)
         
    })    

    const clearForm = () =>
    {
        
    }  

    const checkIfEmailExists = (email: string) => 
    {
        if(!organisation)
        {
            errors.push("no organisation set");
            return false
        }

        //check if email exists in database
        axios.get('/api/teacher/exists/'+  email, data.config)
        .then((response)=>
        {   
            if(response.data === true)
            {
                messages.push("this user alredy exists and they will be added to the organisation, first name and last name will not be overwritten")
            }         
            setIsExistingTeacher(response.data);
        }) 

        //check if email is already assigned to organisation
        axios.get('/api/organisation/checkIfUserAssignedToOrganisation/' + organisation.id + '/' + email, data.config)
        .then((response)=>
        {   
            setIsUserPartOfOrganisation(response.data);
        })
    }
    
    const setEmailAddress = (email: string) =>
    {        
        checkIfEmailExists(email);    
        setEmail(email)       
        
    } 
 
    const getRoles = (id: number | undefined) =>
    {
        if(id === undefined){ 
            console.log("org not set")
            return
        }

         axios.get(`api/organisation/roles/${id}`, data.config)
         .then((response)=>
         {            
             setRoles(response.data);
         })
    }   

    const submit = async () => 
    {
        var currentErrors = []
        if(organisation?.id === null || organisation?.id.toString().length === 0)
        {
            currentErrors.push("Invalid Organisation Id")
        }

        if(firstName === null || firstName.length === 0)
        {
            currentErrors.push("first name does not exist")
        } 

        if(lastName === null || lastName.length === 0)
        {
            currentErrors.push("Last name does not exist")
        } 

        if(email === null || email.length === 0 || !validateEmail(email))
        {
            currentErrors.push("Email does not exist")
        }    

        if(roleId === null)
        {
            currentErrors.push("Role does not exist")
        }

        if(userId === null || userId.length === 0)
        {
            currentErrors.push("UserId does not exist")
        }

        if(isUserPartOfOrganisation)
        {
            currentErrors.push("User already part of organisation")
        }

        var dataJSON:FormData =
        {
            firstName: firstName,
            lastName: lastName,
            organisationId:organisation?.id,
            email: email,
            roleId: roleId,
            userId: userId
        }
        setErrors(currentErrors)
        if(currentErrors.length > 0)
        {
            return
        }

        await axios.post('/api/organisation/adduser',dataJSON, data.config)
        .then((response)=>
        {
            closeModal()
            clearForm()
            setMessages(["User Saved"])

            setTimeout(()=>
            {
                setMessages([])
               
            }, 3000)
        })      
    }

    return(
        _organisation?
        <div id="AddUserToOrganisationForm" className='mt-5'>
            <Messages messages={errors} />     
            <Messages messages={messages} />
                    <div className='form-group'>
                        <label className='label'>First name</label>
                        <input value={firstName? firstName:""}   onChange={(e:React.FormEvent<HTMLInputElement>)=>setFirstName(e.currentTarget.value)} />
                    </div>
                    <div className='form-group'>
                        <label className='label'>Last name</label>
                        <input value={lastName? lastName:""}   onChange={(e:React.FormEvent<HTMLInputElement>)=>setLastName(e.currentTarget.value)}/>
                    </div>
                    <div className='form-group'>
                        <label className='label'>Email</label>
                        <input value={email? email:""}   onChange={(e:React.FormEvent<HTMLInputElement>)=>setEmailAddress(e.currentTarget.value)} type="email" />
                    </div>
                    <div className='form-group'>
                        <label className='label'>UserId <a href={'https://epieos.com/'} target='_blank' rel="noreferrer">find id</a></label>
                        <input value={userId? userId:""}   onChange={(e:React.FormEvent<HTMLInputElement>)=>setUserId((e.currentTarget.value))}/>
                    </div>
                    <div className='form-group'>
                        <label className='label'>Role</label>
                        <select defaultValue={1} value={roleId?.toString()} onChange={(e:React.FormEvent<HTMLSelectElement>)=>setRoleId(parseInt(e.currentTarget.value)) }>                            
                            {roles?.map((role)=>
                            (
                                <option value={role.id}>{role.name}</option>
                            ))}                            
                        </select>
                    </div>
                    <div className='form-group ml-5'>
                        <div className='ml-5 mt-2 w-100'>
                            <button className='btn btn-success' onClick={submit}>Save User To Organisation</button>
                        </div>
                    </div>                  
                    <hr/>
                </div>
            :null
                            )

}


export default AddUserToOrganisationFrom;