interface Role 
{
    id: number,
    name: string
}


const Roles:Role[] = [
    {id:1, name:"Standard"},
    {id:2, name:"Admin"}                  
                     ]

export default Roles