import {FC} from 'react'

interface Props 
{
    messages:string[]
}

const Messages:FC<Props> = ({messages}) =>
{
    return (
    <div className='mt-3'>
         {
            messages.length > 0?
            (<div className="alert alert-danger" role="alert">
                {
                    messages.map((message, index)=>
                    (
                        <span>{message}<br/></span>
                    ))
                }
            </div>)    
            :null
        } 
    </div>)
}


export default Messages
