import {FC, useEffect, useState} from 'react'
import {  useLocation} from 'react-router-dom';
import axios from 'axios';
import Messages from '../HelperComponents/Messages'
import { getOrganisationIdFromURL } from '../HelperComponents/Functions';
import DeleteStudentButton from './DeleteStudentButton';
import EditModelButton from './EditModelButton';
interface Props 
{
    data: Data
}

interface Data 
{
    config:Config,
    id:string,
    isAuthenticated:Boolean
}

interface Config
{
    headers:Headers
}

interface Headers
{
    Authorization:string
}

interface FormData 
{
    firstName:string, 
    lastName:string,
    parentId: string | null,
    organisationId: string | null
}

interface Parent 
{
    id:number,
    firstName:string,
    lastName:string
}

interface Student 
{
    id:number
    firstName:string,
    lastName:string,
    status:string
}

const AddEditStudent:FC<Props> = ({data}) =>
{
    const organisationId = getOrganisationIdFromURL();
    const [parentId, setParentId] = useState<string|null>(null);
    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [parent, setParent] = useState<Parent|null>(null);
    const [errors, setErrors] = useState<string[]>([])
    const [messages, setMessages] = useState<string[]>([])
    const [students, setStudents] = useState<Student[]>([]);
    const [isNextPage, setIsNextPage] = useState<boolean>(false);
    const search = useLocation().search;
    const id:string|null = new URLSearchParams(search).get('parentId')
    const [page, setPage] = useState<number>(1);
    const [showArchived, setShowArchived] = useState<boolean>(false);
    const [searchTerm, setSearchTerm] = useState<string>("");   
    
    useEffect(()=>
    {       
        if(id != null)
        {
            setParentId(id)
            setTimeout(()=>getStudents(id), 1000);   
            getParent(id)  
            
        }  
        
        setTimeout(()=>getStudents(id), 1000); 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(()=>
    {          
        getStudents();  
        // eslint-disable-next-line react-hooks/exhaustive-deps          
    },[])

    const setSearchTermProp = (name: any) => 
        {
         setSearchTerm(name);
         setPage(1);
         getStudents(null, 1, showArchived, name);   
        } 

    const toggleShowArchived = () =>
    {
        
        var archived = !showArchived
        setShowArchived(archived)
        getStudents(null, 1, archived)
        setPage(1)
        
    }

    const refreshStudents = () =>
    {
        getStudents(null, page, showArchived)
    }

    const getStudents = (id:string | null = null, _page: number | null = null, _showArchived: boolean = false, _searchTerm: string | null = null) => 
    {
        let url = `/api/student/parent?organisationId=${organisationId}&page=${_page??page}&isArchived=${_showArchived}`
        let _parentId = id?? parentId;
        let st = _searchTerm??searchTerm
        if(_parentId)
        {
            url += `&parentId=${_parentId}`
        }

        if(st)
        {
            url += `&searchString=${st}`
        }
        axios.get(url, data.config)
         .then((response)=>
         {                 
             setStudents(response.data.students);
             setIsNextPage(response.data.isNextPage)
         })
    }

    const getParent = (id:string) =>
    {        
        setParentId(id);
         axios.get(`/api/parent/${organisationId}/${id}`, data.config)
         .then((response)=>
         {
            
            if(response.data)
            {
                setParent(response.data);
            } else 
            {
                setMessage("No parent found", true)
                setParent(null);
                
            }
             
         })
    }

    const clearForm = () =>
    {
        setFirstName("");
        setLastName("");
    }

    const setMessage = (message: string, isError = false) =>
    {
        if(isError)
        {
            setErrors([message]) 
            setTimeout(()=>
            {              
                setErrors([])           
            }, 3000)
        }
        else 
        {
            setMessages([message])
            setTimeout(()=>
            {          
                setMessages([])           
            }, 3000)
        }        
    }

    const setPagination = (page:number) =>
    {
        setPage(page);
        getStudents(null, page)
    }

    const submit = async () => 
    {
        var currentErrors = []
        if(parentId === null || parentId.toString().length === 0 ){
            currentErrors.push("Enter ParentId")
            
        }
        if(firstName === null || firstName.length === 0 ){
            currentErrors.push("Enter First Name")
            
        }

        if(lastName === null || lastName.length === 0 ){
            currentErrors.push("Enter Last Name")
            
        }       
        setErrors(currentErrors)

        if(currentErrors.length > 0)
        {
            return
        }

        var dataJSON:FormData =
        {
            firstName: firstName,
            lastName: lastName,
            organisationId: organisationId,
            parentId:parentId? parentId.toString()  : null
        }

        await axios.post('/api/student',dataJSON, data.config)
        .then((response)=>
        {            
            clearForm()
            setMessage("Student Saved")
            setTimeout(()=>
            {               
                getStudents()
               
            }, 3000)
        })

    }
    
    return (
    <div className='container'>        
        <div className='mt-3'>
            <h2>New Student</h2>
            <Messages messages={errors} />     
            <Messages messages={messages} />
            <div className='mt-3 form-group'>
                <label>Parent Id</label>
                <input type="text" value={parentId?.toString()} onChange={(e:React.FormEvent<HTMLInputElement>) => getParent(e.currentTarget.value)}></input>
            </div>
            {
                parent?.id != null?
                (
                <div className='mt-5'>
                    <div className='form-group'>
                        <label className='label'>Parent</label>
                        <div><strong>{parent.id} - {parent.firstName} - {parent.lastName}</strong></div>
                    </div>
                    <hr/>
                    <h3 className="mt-2 mb-2">Student Details</h3>
                    <div className='form-group'>
                        <label className='label'>First Name</label>
                        <input type="text" value={firstName} onChange={(e:React.FormEvent<HTMLInputElement>)=>{setFirstName(e.currentTarget.value)}}></input>
                    </div>
                    <div className='form-group'>
                        <label className='label'>Last Name</label>
                        <input type="text" value={lastName} onChange={(e:React.FormEvent<HTMLInputElement>)=>{setLastName(e.currentTarget.value)}}></input>
                    </div>                
                    <div className='mt-4'>
                        <button className='btn btn-success' onClick={submit} >Submit</button>
                    </div>
                </div>
                )
                :
                (
                    <div>No parent set!</div>
                )
            }
                     
        </div>
        <div className='mt-3'>
        <div className='mt-3'>
            <div className="input-group mb-3">
                <input onChange={(e:React.FormEvent<HTMLInputElement>)=>setSearchTermProp(e.currentTarget.value)} type="text" className="form-control" placeholder="...name ...id" aria-label="name .. id" aria-describedby="basic-addon2"/>
                <div className="input-group-append">
                        <span className="btn btn-outline-success" id="basic-addon2" onClick={()=> getStudents()}>Search</span>
                </div>
            </div>
            <div>
                {page <= 1? null : <button className='btn btn-info mr-3' onClick={()=> setPagination(page <= 1? 1: page -1)}>prev</button>}
                {!isNextPage? null : <button className='btn btn-info' onClick={()=> setPagination(page+1)}>next</button>}
                {<div className="form-check mt-3">
                    <input checked={showArchived} className="form-check-input" type="checkbox" onClick={()=>toggleShowArchived()} value="" id="flexCheckDefault"/>
                    <label className="form-check-label" >
                            Show Archived
                    </label>
                </div>}
            </div>
            </div>
                <table className='table table-responsive mt-3'>
                    <thead>
                        <tr>
                            
                                <td>id</td>
                                <td>First name</td>
                                <td>Last name</td>
                                <td>Status</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                          
                        </tr>
                    </thead>
                    <tbody>
                        {
                            students?.map((student, index)=>
                            (
                                <tr key={index+student.id}>
                                    <td>{student.id}</td>
                                    <td>{student.firstName}</td>
                                    <td>{student.lastName}</td>
                                    <td>{student.status}</td>
                                   
                                    <td><a href={`/admin/lesson?studentId=${+student.id}&appId=${organisationId}`} className='btn btn-outline-warning'>+ lesson</a></td>
                                    <td ><EditModelButton setMessages={setMessages} refreshStudents={refreshStudents} status={student.status} data={data} id={student.id} _firstName={student.firstName} _lastName={student.lastName} organisationId={organisationId} model={"student"} /></td>                                    
                                    <td>{student.status === "Archived"?<DeleteStudentButton setMessages={setMessages} refreshStudents={refreshStudents}  data={data} studentId={student.id} />:null}</td>
                                    <td></td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>  
    </div>)
}

export default AddEditStudent;