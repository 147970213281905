import {FC, useState} from 'react'
import axios from 'axios'
import {Modal, Button} from 'react-bootstrap';
import { getOrganisationIdFromURL } from '../HelperComponents/Functions';
interface Props 
{
    parentId:number,
    data: Data
}

interface Data 
{
    config:Config,
    id:string,
    isAuthenticated:Boolean
}

interface Config
{
    headers:Headers
}

interface Headers
{
    Authorization:string
}


const DeleteParentButton:FC<Props> =  ({parentId, data}) =>
{

    const organisationId = getOrganisationIdFromURL();

    const [deleteModalIsOpen, setDeleteIsOpen] = useState<boolean>(false);

    function openDeletModal() {
        setDeleteIsOpen(true);
      }
    
    function closeDeletModal() {
        setDeleteIsOpen(false);
    }

    const deleteParent = () =>
    {
        if(parentId > 0)
        {          
                
                axios.get(`/api/parent/delete/${organisationId}/${parentId.toString()}`, data.config)
                .catch((error)=>
                {
                    alert('error: hasnt been 90 days')
                })
                closeDeletModal();               
        }
    }


    return (
    <div>
        <button className='btn btn-danger' onClick={openDeletModal}>Delete</button>
        <Modal show={deleteModalIsOpen} onHide={closeDeletModal}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure you want to permenantly delete this parent</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div>
                <p>this means that all their related students, lessons, invoices, any payment details will be deleted forever!</p>
            </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeDeletModal}>
            Close
          </Button>
          <Button variant="primary" onClick={deleteParent}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

    </div>)
}


export default DeleteParentButton