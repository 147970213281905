import {FC, useState} from 'react'
import { getOrganisationIdFromURL } from '../../HelperComponents/Functions'
import Messages from '../../HelperComponents/Messages'
import axios from 'axios'
import InvoiceRecords from './InvoiceRecords'

interface Props 
{
    data: Data
}

interface Data 
{
    config:Config,
    id:string,
    isAuthenticated:Boolean,
    roleId?: number | null,
    username?:string| undefined
}

interface Config
{
    headers:Headers
}

interface Headers
{
    Authorization:string
}

interface InvoiceParentDto 
{
    parentId: number,
    parentFullName: string,
    records: InvoiceStudentDto[],
    total: number
}

interface InvoiceStudentDto 
{
    parentId: number,
    parentFullName: string,
    studentFirstName: string
}


interface BillingData 
{
    company: string,
    bank: string,
    account: string,
    sortCode: string,
    accountNo: string
}

var _billingData: BillingData = 
{
    company: 'company',
    bank: 'bank',
    account: 'account',
    sortCode: 'sortCode',
    accountNo: 'accountNo'

} 

const StandardInvoicePanel:FC<Props> = ({data}) => 
{
    const organisationId = getOrganisationIdFromURL();
    const [errors, setErrors] = useState<string[]>([])
    const [messages, setMessages] = useState<string[]>([])
    const [year, setYear] = useState<number | undefined>(2023)
    const [month, setMonth] = useState<number | undefined>(4)
    const [invoiceRecords, setInvoiceRecords] = useState<InvoiceParentDto[]>([])
    const [page, setPage] = useState<number>(1);
    

    ///billing info
    const [company, setCompany] = useState<string>("")
    const [bank, setBank] = useState<string>("")
    const [account, setAccount] = useState<string>("")
    const [sortCode, setSortCode] = useState<string>("")
    const [accountNo, setAccountNo] = useState<string>("")
    const [isBillingInfo, setIsBillingInfo] = useState<boolean>(false)
    const [billingData, setBillingData] = useState<BillingData>(_billingData)
    const [isNextPage, setIsNextPage] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const setBillingInfo = () =>
    {    var billingData: BillingData = 
        {
            company: company,
            bank: bank,
            account: account,
            sortCode: sortCode,
            accountNo: accountNo

        } 
        setBillingData(billingData)
        setIsBillingInfo(true)
        messages.push("Bililng info saved")
       clearMessages()
    }

    const clearMessages = () =>
    {
        setTimeout(()=>
        {
            setErrors([])
            setMessages([])
           
        }, 4000)
    }

    const setMonthProp = (month: number) => 
    {
        setPage(1);
        setMonth(month)
    }

    const setYearProp = (year: number) => 
    {
        setPage(1);
        setYear(year)
    }   


    const isInvoicesDownloadable = (month:number | undefined, year:number | undefined): boolean => 
    {
       if(data.roleId === 3)
       { 
            return true;
       }
        let _month = month? month - 1 : 0
        return new Date() >= new Date(year??9999, _month, 26);
    }

    const setPagination = (page:number) =>
    {
        setLoading(true);
        setPage(page);
        search(page)
    }

    const search = async (_page: number) => 
    {
        var currentErrors = []

        if(organisationId === null || organisationId === "")
        {
            currentErrors.push("need to set organisationId")
        }

        if(!isBillingInfo && organisationId !== "2")
        {
            currentErrors.push("Billing data incomplete")
        }
        let _isInvoicesDownloadable = isInvoicesDownloadable(month, year)

        if(!_isInvoicesDownloadable)
        {
            currentErrors.push("Invoices not ready yet...")            
        }


        setErrors(currentErrors)
        if(currentErrors.length > 0)
        {
            clearMessages()
            return
        }

        await axios.get(`/api/invoice/records/${organisationId}/${year}/${month}/${_page??page}`, data.config)
        .then((response)=>
        { 
            setIsNextPage(response.data.isNextPage)           
            setInvoiceRecords(response.data.invoices)
            setLoading(false)
            
            //setMessages([""])
            clearMessages();        
        })      
    }



    return (
        <div className='container'>
            <h1 className='mb-5'>Invoice Panel</h1>
            <Messages messages={errors} />     
            <Messages messages={messages} />

            <div className='row mb-5'>
                <div className='col'>
                    <div className='form-group'>
                        <label className='label'>Year</label>
                        <select className='form-group'  value={year} onChange={(e:React.FormEvent<HTMLSelectElement>)=>setYearProp(parseInt(e.currentTarget.value))}>
                            <option value={undefined}>None</option>                            
                            <option value={2024}>2024</option>
                            <option value={2025}>2025</option>
                            <option value={2026}>2026</option>
                            <option value={2027}>2027</option>
                        </select>
                    </div>

                    <div className='form-group'>
                        <label className='label'>Month</label>
                        <select className='form-group' value={month} onChange={(e:React.FormEvent<HTMLSelectElement>)=>setMonthProp(parseInt(e.currentTarget.value))}>
                            <option value={undefined}>None</option>
                            <option value={1}>January</option>
                            <option value={2}>Febuary</option>
                            <option value={3}>March</option>
                            <option value={4}>April</option>
                            <option value={5}>May</option>
                            <option value={6}>June</option>
                            <option value={7}>July</option>
                            <option value={8}>August</option>
                            <option value={9}>September</option>
                            <option value={10}>October</option>
                            <option value={11}>November</option>
                            <option value={12}>December</option>
                        </select>
                    </div>

                    <div className='mb-5'>
                        <button disabled={!month || !year} className='btn btn-outline-info' onClick={(e)=> search(page)}>Search</button>
                    </div>

                    {page <= 1? null : <button disabled={loading} className='btn btn-info mr-3' onClick={()=> setPagination(page <= 1? 1: page -1)}>prev</button>}
                    {!isNextPage? null : <button disabled={loading} className='btn btn-info' onClick={()=> setPagination(page+1)}>next</button>}
                </div>
                <div className='col'>
                    {
                        organisationId === "2"?null:
                        <div>
                            <div className='form-group'>
                                <label className='label'>Organisation Name</label>
                                <input disabled={isBillingInfo} value={company} onChange={(e)=> setCompany(e.target.value)} type='text'></input>
                            </div>
                            <div className='form-group'>
                                <label className='label'>Bank</label>
                                <input disabled={isBillingInfo}  value={bank} onChange={(e)=> setBank(e.target.value)} type='text'></input>
                            </div>
                            <div className='form-group'>
                                <label className='label'>Account Name</label>
                                <input disabled={isBillingInfo}  value={account} onChange={(e)=> setAccount(e.target.value)} type='text'></input>
                            </div>
                            <div className='form-group'>
                                <label className='label'>Sort code</label>
                                <input disabled={isBillingInfo}  value={sortCode} onChange={(e)=> setSortCode(e.target.value)} type='text'></input>
                            </div>
                            <div className='form-group'>
                                <label className='label'>Account No</label>
                                <input disabled={isBillingInfo}  value={accountNo} onChange={(e)=> setAccountNo(e.target.value)} type='text'></input>
                            </div>
                            <div>
                                <button disabled={isBillingInfo || company?.length === 0 || bank?.length === 0 
                                    || account?.length === 0 || sortCode?.length === 0 || accountNo?.length === 0} className='btn btn-outline-info' onClick={(e)=> setBillingInfo()}>Save</button>
                            </div>
                        </div>
                    }
                </div>
            </div>

            {
                ((organisationId === "2" || (month !== undefined && year !== undefined && billingData)) && invoiceRecords.length === 0)? <h3>No invoice Records Found!</h3> : null
            }            

            {
                ((organisationId === "2" || (month !== undefined && year !== undefined && billingData)) && invoiceRecords.length > 0)?
                 <InvoiceRecords records={invoiceRecords} data={data} month={month} year={year} billingData={billingData}/> : null
            }
            

            <div>

            </div>
        </div>
    )
}


export default StandardInvoicePanel