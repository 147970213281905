import {FC, useEffect, useState} from 'react'
import "react-datepicker/dist/react-datepicker.css";
import '../css/animation.css'
import {Modal} from 'react-bootstrap';
import { AppData } from './Types/types';
import { nounIt } from './FunctionalComponents/HelperComponents/Functions';

interface Props 
{
    data: AppData
}

interface Organisation
{
  id: number,
  name: string
}

const SignIn:FC<Props> =  ({data}) => {

    const [userName, setUserName] = useState<string | null>(null);
    const [organisations] = useState<Organisation[]>([]);
    const [style, setStyle] = useState<string>('');

   // const terminalRef = useRef(terminalLineData);

    //modal  
    const [isOpen, setIsOpen] = useState<boolean>(false);
   
    function closeModal() {
      setIsOpen(false);
    }

    function selectOrganisation(o: Organisation)
    {
      closeModal()
      data.setOrganisationId(o.id);
      
    }
 
    useEffect(()=>
    {
      setUserName((data.username || data.user?.given_name)??'')
    },[data.username, data.user?.given_name])
    
  
        return(
            <div className={`container mt-5 pt-5 ${style}`}>
                {
                userName != null?
                (                
                    
                   <div>                    
                    <div onClick={()=>setStyle("pink")}>
                      <h1 className={`mb-3`}>Welcome {nounIt(userName)}!!</h1>
                     </div>   
                   </div>     
                           
                )
                :
                null

              }   
           
              <Modal show={isOpen} onHide={closeModal}>
                <Modal.Header closeButton>
                <Modal.Title>Choose Organisation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                   {
                    organisations.map((o)=>
                    <div className='mb-3 row mr-5 ml-5'>
                      <div className="col">{o.name}</div><button className='col btn btn-outline-primary' onClick={(e) => selectOrganisation(o)}>sign in</button>
                    </div>
                    )
                   }
                </Modal.Body>
            </Modal>   
               
            </div>
        )
    



}

export default SignIn;