import {FC, useState} from 'react'

import axios from 'axios'
import {Modal, Button} from 'react-bootstrap';
import ArchiveModelButton from './ArchiveModelButton';
interface Props
{
    status:string,
    data: Data,
    id:number,
    _firstName:string,
    _lastName:string,
    organisationId:string | null,
    refreshStudents: Function,
    setMessages: Function,
    model: string
}


interface Data 
{
    config:Config,
    id:string,
    isAuthenticated:Boolean
}

interface Config
{
    headers:Headers
}

interface Headers
{
    Authorization:string
}

interface FormData 
{
    parentId?: number
    studentId?:number 
    firstName: string
    lastName:string

    
}


const EditModelButton:FC<Props> = ({status, data, id, _firstName, _lastName, organisationId, refreshStudents, setMessages, model}) =>
{    

    const [errors, setErrors] = useState<string[]>([])
    const [firstName, setFirstName] = useState<string>(_firstName);
    const [lastName, setLastName] = useState<string>(_lastName);

    //modal
    const [isOpen, setIsOpen] = useState<boolean>(false);


    function openModal() {
        setIsOpen(true);
      }
    
    function closeModal() {
        setIsOpen(false);
    }


    const submit = async () => 
    {
        var currentErrors = []

        if(id === null)
        {
            currentErrors.push(model+" not set")            
        }       

        if(firstName === null || firstName === "")
        {
            currentErrors.push("first name not set")            
        } 

        if(lastName === null || lastName === "")
        {
            currentErrors.push("last name not set")            
        } 

        var dataJSON:FormData =
        {
            firstName: firstName, 
            lastName: lastName
        }

        if(model === "student"){
            dataJSON.studentId = id;
        }else if(model === "parent"){
            dataJSON.parentId = id;
        }   

        setErrors(currentErrors)
        if(currentErrors.length > 0)
        {
            return
        }

        await axios.post(`/api/${model}/edit/${organisationId}/${id}`,dataJSON, data.config)
        .then((response)=>
        {
            closeModal()
            setMessages([model+" updated"])
            setTimeout(()=>
            {
                refreshStudents()
                setMessages([])
               
            }, 2000)
        })      
    }
    
    return (
        <div>
            <button className='btn btn-outline-info' onClick={()=> openModal()}>Edit</button>

            <Modal show={isOpen} onHide={closeModal}>
                <Modal.Header closeButton>
                <Modal.Title>Edit {model}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div className='mt-5 modal-form'>
                    <div className='form-group'>
                        <label className='label'>First Name</label>
                        <input type="text" defaultValue={firstName} value={firstName} onChange={(e:React.FormEvent<HTMLInputElement>)=>{setFirstName(e.currentTarget.value)}}></input>
                    </div>
                    <div className='form-group'>
                        <label className='label'>Last Name</label>
                        <input type="text" defaultValue={lastName} value={lastName} onChange={(e:React.FormEvent<HTMLInputElement>)=>{setLastName(e.currentTarget.value)}}></input>
                    </div>   
                    <div><ArchiveModelButton setMessages={setMessages} refreshStudents={refreshStudents} status={status} data={data} id={id} organisationId={organisationId} model={model}/></div>
                </div>

                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={closeModal}>
                    Close
                </Button>
                <Button variant="primary" onClick={submit}>
                    Save
                </Button>
                </Modal.Footer>
            </Modal>
          
        </div>
    )
}

export default EditModelButton;