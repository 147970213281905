import {FC, useState} from 'react'

import axios from 'axios'
import {Modal, Button} from 'react-bootstrap';
import { Data } from '../../Types/types';
import Messages from '../HelperComponents/Messages';
interface Props
{
    data: Data,
    lessonId:string,
    organisationId:string | null,
    status: string | null,
    getStudentLessons: Function,
    setMessages: Function
}


const ArchiveLessonButton:FC<Props> = ({data, lessonId, organisationId, status, getStudentLessons, setMessages}) =>
{    

    const [errors, setErrors] = useState<string[]>([])

    //modal
    const [isOpen, setIsOpen] = useState<boolean>(false);


    function openModal() {
        setIsOpen(true);
      }
    
    function closeModal() {
        setMessages(["lesson updated"])
        setIsOpen(false);
    }


    const submit = async () => 
    {
        var currentErrors = []

        if(lessonId === null)
        {
            currentErrors.push("lesson not set")            
        }       

        if(organisationId === null)
        {
            currentErrors.push("organisation not set")            
        }  

     

        setErrors(currentErrors)
        if(currentErrors.length > 0)
        {
            return
        }

        await axios.post(`/api/lesson/archive/${organisationId}/${lessonId}`,{}, data.config)
        .then((response)=>
        {
            closeModal()
            setTimeout(()=>
            {
                getStudentLessons()
                setMessages([])
               
            }, 2000)
        })      
    }
    
    return (
        <div>
            <button className='btn btn-outline-danger' onClick={()=> openModal()}>{status === "Archived"? "Un-Archive": "Archive"}</button>

            <Modal show={isOpen} onHide={closeModal}>
                <Modal.Header closeButton>
                <Modal.Title>Are you sure you want to {status === "Archived"? "Un-Archive": "Archive"} this lesson?</Modal.Title>
                   
                </Modal.Header>
                <Modal.Body>
                <Messages messages={errors} />
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={closeModal}>
                    Close
                </Button>
                <Button variant="primary" onClick={submit}>
                    {status === "Archived"? "Un-Archive": "Archive"}
                </Button>
                </Modal.Footer>
            </Modal>
          
        </div>
    )
}

export default ArchiveLessonButton;