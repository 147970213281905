import  {FC, useState} from 'react'
import { getOrganisationIdFromURL } from '../../HelperComponents/Functions'
import {createInvoicePdf} from '../../HelperComponents/PdfService'
import axios from 'axios'
import Organisations from '../../../Enums/Organisations'
import billingInfo from '../../../Enums/BillingData'
import { InvoiceProps, InvoiceParentDto, InvoiceLesson } from '../../../Types/types'

const InvoiceRecords:FC<InvoiceProps> = ({records, data, month, year, billingData}) => 
{
    const organisationId = getOrganisationIdFromURL();
    const [loading, setLoading] = useState<boolean>(false)
    const [_index, setIndex] = useState<number | null>(null)


    const getInvoice = async(record: InvoiceParentDto, index: number) =>
    {
        setIndex(index);
        setLoading(true)
        setTimeout(()=>
        {
            createPDF(record);
            
        }, 1200)
    }

    const createPDF = async (record: InvoiceParentDto) =>
    {      
        
        await axios.get(`/api/invoice/parent-monthly-record/${organisationId}/${record.parentId}/${year}/${month}`, data.config)
        .then((response)=>
        {
            let lessons: InvoiceLesson[] = response.data;
            createInvoicePdf(record.parentId, 
                record.parentFullName,
                 lessons,
                  year??0, month??0, organisationId === Organisations.MSLC? billingInfo : billingData, organisationId === Organisations.MSLC);
                  setLoading(false)
        })  
    }



    return (
        <table className='table table-responsive'>
           {records?.map(((record, index)=>
           (
            <tr>
                <td>{record.parentId}</td>
                <td>{record.parentFullName}</td>
                <td>£{record.total}</td>
                <td><button className='btn btn-outline-warning' onClick={(e)=> getInvoice(record, index)}>{loading && _index === index? 'loading...' : 'Download'}</button></td>
            </tr>
           )))}
        </table>
    )
}


export default InvoiceRecords