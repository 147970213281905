import Invoice from "../components/FunctionalComponents/Invoice/Invoice";
import AddEditLesson from "../components/FunctionalComponents/Lesson/AddEditLesson";
import MyLessons from "../components/FunctionalComponents/MyLessons/MyLessons"
import AddEditParent from "../components/FunctionalComponents/Parent/AddEditParent";
import Register from "../components/FunctionalComponents/Register"
import Settings from "../components/FunctionalComponents/Settings/Settings";
import AddEditStudent from "../components/FunctionalComponents/Student/AddEditStudent";
import AddEditTakenLesson from "../components/FunctionalComponents/TakenLesson/AddEditTakenLesson";
import AddEditTeacher from "../components/FunctionalComponents/Teacher/AddEditTeacher";
import SignIn from "../components/SignIn"
import Report from "../components/FunctionalComponents/Reports/Report";
import Dashboard from "../components/FunctionalComponents/Omega/Dashboard";
import OaDashboard from "../components/FunctionalComponents/Dashboard/Dashboard";
import Organisations from "../components/FunctionalComponents/Omega/Organisations/Organisations";
import RemoveUser from "../components/FunctionalComponents/Omega/Organisations/OrganisationSettings";
import OrganisationConnectedAccountPage from "../components/FunctionalComponents/Omega/Organisations/OrganisationConnectedAccountPage";
import AdminPanel from "../components/FunctionalComponents/AdminPanel";
import OaPanel from "../components/FunctionalComponents/OmegaPanel";
class Page 
{
  constructor(component: any, path: string)
  {
    this.Component = component;
    this.path = path;
  }
  Component: any;
  path: string;
}

export var publicRoutes = 
[
  new Page(SignIn, '/')
]

export var authedRoutes = 
[
  new Page(Register, '/register'),
  new Page(MyLessons, '/history')
]

export var adminRoutesAll = 
[
  new Page(AddEditParent, '/admin/parent'),
  new Page(AddEditStudent, '/admin/student'),
  new Page(AddEditLesson, '/admin/lesson'),
  new Page(Invoice, '/admin/invoice-panel'),
  new Page(AdminPanel, '/admin')
]

export var adminRoutesOaOnly = 
[
  new Page(AddEditTakenLesson, '/admin/taken-lesson'),
  new Page(Settings, '/admin/settings'),
  new Page(AddEditTeacher, '/admin/teacher'),
  new Page(Report, '/admin/report'),
  new Page(OaDashboard, '/admin/dashboard'),
  new Page(OaPanel, '/oa-admin')
]

export var omegaRoutes = 
[
  new Page(Dashboard, '/omega'),
  new Page(Organisations, '/omega/organisations'),
  new Page(RemoveUser, '/omega/organisations/users'),
  new Page(OrganisationConnectedAccountPage, '/omega/organisations/connected-account')
]


