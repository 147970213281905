interface LooseObject {
    [key: string]: any
}

var Prices:LooseObject = {};

//

//ks3
Prices["3GE"] = 17.5
Prices["3OE"] = 23.5

Prices["3GM"] = 17.5
Prices["3OM"] = 23.5

Prices["3GS"] = 17.5
Prices["3OS"] = 23.5

//alevel

Prices["AG1"] = 25 // Economics
Prices["AO1"] = 35 // Economics

Prices["AG2"] = 25 // psychology
Prices["AO2"] = 35 // psychology

Prices["AGE"] = 25
Prices["AOE"] = 35

Prices["AGM"] = 25
Prices["AOM"] = 35

Prices["AGP"] = 25
Prices["AOP"] = 35

Prices["AOB"] = 35
Prices["AGB"] = 25

Prices["AOC"] = 35
Prices["AGC"] = 25


//gcse


Prices["GGM"] = 20
Prices["GOM"] = 27.5

Prices["GOE"] = 27.5
Prices["GGE"] = 20

Prices["GOS"] = 27.5
Prices["GGS"] = 20

//ks1
Prices["1GE"] = 16
Prices["1OE"] = 21

Prices["1OS"] = 21
Prices["1GS"] = 16

Prices["1OM"] = 21
Prices["1GM"] = 16


//ks2

Prices["2GM"] = 16
Prices["2OM"] = 21

Prices["2GS"] = 16
Prices["2OS"] = 21

Prices["2GE"] = 16
Prices["2OE"] = 21



export default Prices