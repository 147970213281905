
import {FC, useEffect, useState} from 'react'
import DatePicker from "react-datepicker";
import Messages from '../HelperComponents/Messages'
import axios from 'axios'
import {Modal} from 'react-bootstrap';
import { useLocation} from 'react-router-dom';
import { getPlanId, showErrorResponse, formatDate } from '../HelperComponents/Functions';
const MaxPageSizeLimit = 100;


interface Props 
{
    data: Data
}

interface Data 
{
    config:Config,
    id:string,
    isAuthenticated:Boolean
}

interface Config
{
    headers:Headers
}

interface Headers
{
    Authorization:string
}

interface TakenLesson 
{
    id: number,
    subject:string,
    student:string,
    teacher:string,
    totalPay:number,
    totalFee:number,
    lessonDate:Date,
    invoiceId:number,
    stripeInvoiceId:string,
    stripeRefundId:string,
    status:string,
    hours:number

}

interface Teacher 
{
    id:number,
    fullName:string
}

const AddEditTakenLesson:FC<Props> = ({data}) => 
{
    const [fromDate , setFromDate] = useState<Date | null>(new Date());
    const [toDate , setToDate] = useState<Date | null>(new Date());
    const [teachers, setTeachers] = useState<Teacher[]>([]);
    const [pageSize , setSizePage] = useState<number>(10);
    const [parentId , setParentId] = useState<number | null>(null);
    const [studentId , setStudentId] = useState<number | null>(null);
    const [invoiceId , setInvoiceId] = useState<number | null>(null);
    const [stripeInvoiceId , setStripeInvoiceId] = useState<string | null>(null);
    const [stripeRefundId , setStripeRefundId] = useState<string | null>(null);

    //modal
    var [selectedLessonIndex, setSelectedLessonIndex] = useState<number>(0);
    var [action, setAction] = useState<number | null>(null);
    const [modalIsOpen, setIsOpen] = useState<boolean>(false);

    const [teacherId , setTeacherId] = useState<number | null>(null);
    const [lessons, setLessons] = useState<TakenLesson[]>([]);
    const [numOfPages] = useState<number>(0);
    const [maxPageSize] = useState<number>(MaxPageSizeLimit);
    
    const [errors, setErrors] = useState<string[]>([])
    const [messages, setMessages] = useState<string[]>([])

    const search = useLocation().search;
    const iId:string | null = new URLSearchParams(search).get('invoiceId')
    const siId:string | null = new URLSearchParams(search).get('sid')

  //  const [organisationPlanId, setOrganisationPlanId] = useState<number | null>(null)
    const [isProPlan, setIsProPlan] = useState<boolean>(false);

    const [page, setPage] = useState<number>(1);

    const queryParams = new URLSearchParams(window.location.search)
    const organisationId = queryParams.get('appId');

    const setPagination = (page:number) =>
    {
        setPage(page);
        submit(page)
    }

    useEffect(() => 
    {
        
        if(iId != null)
        {
            setInvoiceId(parseInt(iId))         
        }

        if(siId != null)
        {
            setStripeInvoiceId(siId)         
        }

        if(organisationId)
        {
            axios.get(`/api/organisation/current-invoice-plan/${organisationId}`, data.config).then(
                (response)=>
            {               
               // setOrganisationPlanId(response.data)
                setIsProPlan(response.data === getPlanId("Business"))               
            })
        }  

        getTeachers()
    },[])

    function openModal() {
        setIsOpen(true);
    }
    
    function closeModal() {
        setIsOpen(false);
    }
  

    const getTeachers = async () => 
    {
        await axios.get('/api/teacher/all', data.config)
        .then((response)=>
        {
            setTeachers(response.data); 
        })
    }

    const submitChanges = async () => 
    {
        var lesson = lessons[selectedLessonIndex]
        var id = lesson.id
        if(action != null && id != null)
        {
            if(lesson.stripeRefundId != null && (lesson.stripeRefundId.length > 0))
            {

                closeModal()
                setErrors(['refund already exists'])
                setTimeout(()=>{setErrors([])}, 3000)            
                return 
            }
            closeModal()
            axios.put('/api/taken-lesson/action/'+action.toString()+"/"+id.toString() , null , data.config)
            .catch((error)=>
            {                
                showErrorResponse(error.response.request.status, error.response.request.responseText)
            })

        }else
        {
            alert("fail")
        }
    }

    const selectLesson = async (index:number)=> 
    {
        setSelectedLessonIndex(index);   
        openModal()  
    } 

    const submit = async (_page:number | null) => 
    {     
        var currentErrors = []
        if(fromDate === null)
        {
            currentErrors.push("Enter From Date")
        }

        if(toDate === null)
        {
            currentErrors.push("Enter To Date")
        }

        if(toDate != null &&  fromDate != null)
        {
            if(toDate < fromDate)
            {
                currentErrors.push("To Date can't be before From Date")
            }   
        }
        if(pageSize === null || pageSize < 1 || pageSize > MaxPageSizeLimit)
        {
            currentErrors.push("Page Size must be between 0 and "+ maxPageSize)
        }        

        setErrors(currentErrors)

        if(currentErrors.length > 0)
        {
            return
        }
     
        var url = `/api/taken-lesson/between?organisationId=${organisationId}&`

        if(fromDate != null)
        {
            url += `fromDate=${formatDate(fromDate)}&`
        }

        if(toDate != null)
        {
            url += `toDate=${formatDate(toDate)}&`
            
        }

        if(teacherId != null)
        {
            url += `teacherId=${teacherId}&`
        }
        
        if(parentId != null && parentId > 0)
        {
            url += `parentId=${parentId}&`
        }

        if(studentId != null && studentId > 0)
        {
            url += `studentId=${studentId}&`
        }

        if(invoiceId != null && invoiceId > 0)
        {
            url += `invoiceId=${invoiceId}&`
        }

        if(stripeInvoiceId != null)
        {
            url += `stripeInvoiceId=${stripeInvoiceId}&`
        }

        if(stripeRefundId != null)
        {
            url += `stripeRefundId=${stripeRefundId}&`
        }
   
        await axios.get(url, data.config)
        .then((response)=>
        {
           setLessons(response.data)
        })
    }

    return (
    <div className='container'>
        <h1 className='mb-3'>Dashboard</h1>
       

        <div className=''>
            <div className='form-group'>
                <label className='label text-left ml-3'>From date</label>
                <div>
                    <DatePicker 
                            placeholderText='Select date' 
                            dateFormat={'dd/MM/yyyy'}
                            onChange={date => setFromDate(date)}
                            selected={fromDate}/>
                </div>
            </div>
            <div className='form-group'>
                <label className='label text-left ml-3'>To date</label>
                <div>
                    <DatePicker 
                            placeholderText='Select date' 
                            dateFormat={'dd/MM/yyyy'}
                            onChange={date => setToDate(date)}
                            selected={toDate}/>
                </div>
            </div>
            <div className='form-group'>
                <label className='label text-left ml-3'>Teacher</label>
                <div>
                    <select onSelect={(e:React.FormEvent<HTMLSelectElement>)=>setTeacherId(parseInt(e.currentTarget.value))} >
                            <option value={0}>None</option>
                        {
                            teachers?.map((teacher, index)=>
                            (
                                <option value={teacher.id}>{teacher.fullName}</option>
                            ))
                        }
                    </select>
                </div>
            </div>
            <div className='form-group'>
                <label className='label text-left ml-3'>Parent Id</label>
                <div>
                    <input type="number" onChange={(e:React.FormEvent<HTMLInputElement>)=>setParentId(parseInt(e.currentTarget.value))}></input>
                </div>
            </div>
            <div className='form-group'>
                <label className='label text-left ml-3'>Student Id</label>
                <div>
                    <input type="number" onChange={(e:React.FormEvent<HTMLInputElement>)=>setStudentId(parseInt(e.currentTarget.value))}></input>
                </div>
            </div>
            
            {
                isProPlan?
                    <div className='form-group'>
                        <label className='label text-left ml-3'>Invoice Id</label>
                        <div>
                            <input value={invoiceId?.toString()} type="number" onChange={(e:React.FormEvent<HTMLInputElement>)=>setInvoiceId(parseInt(e.currentTarget.value))}/>
                        </div>
                    </div>
                :null
            }
            {
                isProPlan?
                    <div className='form-group'>
                        <label className='label text-left ml-3'>Stripe Invoice Id</label>
                        <div>
                            <input value={stripeInvoiceId?.toString()} type="text" onChange={(e:React.FormEvent<HTMLInputElement>)=>setStripeInvoiceId((e.currentTarget.value))}></input>
                        </div>
                    </div>
                :null
            }
            {
                isProPlan?
                    <div className='form-group'>
                        <label className='label text-left ml-3'>Stripe Refund Id</label>
                        <div>
                            <input type="text" onChange={(e:React.FormEvent<HTMLInputElement>)=>setStripeRefundId((e.currentTarget.value))}></input>
                        </div>
                    </div>
                :null
            }            
            
            <div className='form-group'>
                <label className='label text-left ml-3'>Page Size</label>
                <div>
                    <input value={pageSize} className="text-left" type="number" min={1} max={maxPageSize} onChange={(e:React.FormEvent<HTMLInputElement>)=>{setSizePage(parseInt(e.currentTarget.value))}} />
                </div>
            </div>
            <div>
                <button onClick={()=>submit(null)} className='btn btn-outline-info'>submit</button>
            </div>
        </div>

        <Messages messages={errors} />     
        <Messages messages={messages} />

        <div>
            {page <= 1? null : <button className='btn btn-info mr-3' onClick={()=> setPagination(page <= 1? 1: page -1)}>prev</button>}
            {lessons && lessons.length === 0? null : <button className='btn btn-info' onClick={()=> setPagination(page+1)}>next</button>}
        </div>

        <div className='mt-3'>
            <table className='table table-responsive'>
                <thead>
                    <tr>
                        <td>Id</td>
                        <td>Student</td>
                        <td>Teacher</td>
                        <td>Subject</td>
                        <td>Hours</td>
                        <td>Total Fee</td>
                        <td>Total Pay</td>
                        <td>Date</td>
                        {isProPlan ? <td>Status</td> : null}
                        {isProPlan ? <td>InvoiceId</td> : null}
                        {isProPlan ? <td>Stripe Invoice</td> : null}
                        {isProPlan ? <td>Stripe Refund</td> : null}
                        <td>-</td>
                    </tr>
                </thead>
               
                    <tbody>
                        {
                            lessons?.map((lesson, index)=>
                            (
                                <tr>
                                    <td>{lesson.id}</td>
                                    <td>{lesson.student}</td>
                                    <td>{lesson.teacher}</td>
                                    <td>{lesson.subject}</td>
                                    <td>{lesson.hours}</td>
                                    <td>{lesson.totalFee}</td>
                                    <td>{lesson.totalPay}</td>
                                    <td>{formatDate(lesson.lessonDate)}</td>
                                    {isProPlan ?<td>{lesson.status}</td> : null}
                                    {isProPlan ? <td>{lesson.invoiceId}</td> : null}
                                    {isProPlan ? <td>{lesson.stripeInvoiceId}</td>: null}
                                    {isProPlan ? <td>{lesson.stripeRefundId}</td> : null}
                                    {isProPlan? <td>
                                        {
                                            lesson.stripeInvoiceId != null && lesson.stripeRefundId === null? 
                                                <button
                                                    onClick={()=>selectLesson(index)} 
                                                    className='btn btn-outline-danger'>
                                                    refund
                                                </button>
                                            :<div><button className='btn btn-warning' disabled={true}>Refunded</button></div>
                                        }
                                    
                                    </td>
                                    : null}
                                </tr>
                            ))
                        }
                    </tbody>
                          
            </table>
        </div>

        <div className='mt-3'>
            {Array(numOfPages).fill(1).map((el, i) =>(
                <button onClick={()=>submit(i+1)}>{i+1}</button>
            ))}
        </div>

        <Modal show={modalIsOpen} onHide={closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>Choose action</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                    <div>
                        <h5>Select Action:</h5>                        
                        <button onClick={(()=>{setAction(0)})} className='btn btn-primary mb-3'>Refund lesson</button>
                    </div>
                    <hr/>
                    {/* <div><button onClick={(()=>{setAction(1)})} className='btn btn-primary mb-3'>Re-invoice lesson</button></div> */}
                  
                    <div className='mb-5'>
                        <h4>Selected Action</h4>
                        {
                            action === 0?
                            (
                                <div>
                                    <h5>Refund lesson</h5>
                                    <p>
                                        this lesson will be refunded!
                                    </p>
                                </div>
                            ):null
                        }
                        {
                            action === 1?
                            (
                                <div>
                                    <h5>Re-invoive lesson</h5>
                                    <p>
                                        a new lesson will be created with the same details, this lesson will still exist but its status will be updated to reinvoiced
                                    </p>
                                </div>
                            ):null
                        }
                    </div>
                                       
            </Modal.Body>
            <Modal.Footer>
                <button className='btn btn-danger' onClick={closeModal}>close</button>
                {
                        action != null?
                        (                           
                                <button className='btn btn-success' onClick={() => submitChanges()}>Submit changes</button>                       
                        )
                        :null
                    }  
            </Modal.Footer>      
      </Modal>

    </div>)
}


export default AddEditTakenLesson