import { withAuthenticationRequired } from "@auth0/auth0-react";
import  { ComponentType, FC} from "react";
import { PageLoader } from "./page-loader";



interface AuthenticationGuardProps {
  component: ComponentType
  data:Data,
  isAuthenticated:Boolean,
  hasRequiredAuth:Boolean
}

interface Data 
{
  config:Config,
  roleId?: number |  null
}

interface Config 
{
  roles:Array<string>
}

interface IMyProps {
  data: Object,
  isAuthenticated:Boolean
}

export const AuthenticationGuard: FC<AuthenticationGuardProps> = ({ component, data, isAuthenticated, hasRequiredAuth }:AuthenticationGuardProps) => {

  
  if(!hasRequiredAuth)
  {
    return null;
  }
  let Component:FC<IMyProps> = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <div className="page-layout">
        <PageLoader />
      </div>
    ),
  });

  return <Component data={data} isAuthenticated={isAuthenticated}   />;
};
