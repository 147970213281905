interface LooseObject {
    [key: string]: any
}

var Commissions:LooseObject = {};

//

//ks3
Commissions["3GE"] = 9
Commissions["3OE"] = 12

Commissions["3GM"] = 9
Commissions["3OM"] = 12

Commissions["3GS"] = 9
Commissions["3OS"] = 12

//alevel

Commissions["AG1"] = 13.5 // Economics
Commissions["AO1"] = 18 // Economics

Commissions["AG2"] = 13.5 // psychology
Commissions["AO2"] = 18 // psychology

Commissions["AGE"] = 13.5
Commissions["AOE"] = 18

Commissions["AGM"] = 13.5
Commissions["AOM"] = 18

Commissions["AGP"] = 13.5
Commissions["AOP"] = 18

Commissions["AOB"] = 18
Commissions["AGB"] = 13.5

Commissions["AOC"] = 18
Commissions["AGC"] = 13.5


//gcse


Commissions["GGM"] = 10.5
Commissions["GOM"] = 13.5

Commissions["GOE"] = 13.5
Commissions["GGE"] = 10.5

Commissions["GOS"] = 13.5
Commissions["GGS"] = 10.5

//ks1
Commissions["1GE"] = 13.5
Commissions["1OE"] = 7.5

Commissions["1OS"] = 10.5
Commissions["1GS"] = 7.5

Commissions["1OM"] = 10.5
Commissions["1GM"] = 7.5


//ks2

Commissions["2GM"] = 7.5
Commissions["2OM"] = 10.5

Commissions["2GS"] = 7.5
Commissions["2OS"] = 10.5

Commissions["2GE"] = 7.5
Commissions["2OE"] = 10.5



export default Commissions