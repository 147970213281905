import {FC} from 'react'
import { DashboardProps } from '../../Types/types'

const AccountInfoTable:FC<DashboardProps> = ({data, balanceList, title}) => 
{

    return (
  
                     <div className='card card-body'>
                        <div className='card-title bold'>{title}</div>
                        <div>
                                <div>Amount: £{balanceList?.[0]?.amount??"n/a"}</div>
                                <div>Currency: {balanceList?.[0]?.currency}</div>
                        </div>
                    </div>
           
    )
}


export default AccountInfoTable