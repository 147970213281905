import React from 'react';
import { AppData } from '../../Types/types';


export interface StaticRouterProps {
    children?: React.ReactNode
}
export const Premium = (props:{children: any, data: AppData}) =>{
    let isPremium = false;

    if(props.data.isPremium == true){isPremium = true}
    
    if(isPremium){return <>{props.children}</>}

    return <div></div>
    
}


export default Premium