import {FC, useEffect, useState} from 'react'
import axios from 'axios';
import Messages from '../HelperComponents/Messages'
import { getOrganisationIdFromURL } from '../HelperComponents/Functions';
import Roles from '../../Enums/Roles';
import { validateEmail } from '../HelperComponents/Functions'

interface Props 
{
    data: Data
}

interface Data 
{
    config:Config,
    id:string,
    isAuthenticated:Boolean
}

interface Config
{
    headers:Headers
}

interface Headers
{
    Authorization:string
}

interface FormData 
{
    firstName:string, 
    lastName:string,
    email:string,
    roleId: number,
    organisationId: string | null,
    userId: string
}

interface Teacher
{
    id:string,
    role:string,
    firstName:string, 
    lastName:string,
    email:string
}

const AddEditTeacher:FC<Props> = ({data}) => 
{

    const organisationId = getOrganisationIdFromURL();
    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [role, setRole] = useState<number>(1);
    const [userId, setUserId] = useState<string>("");
    const [errors, setErrors] = useState<string[]>([])
    const [messages, setMessages] = useState<string[]>([]);
    const [teachers, setTeachers] = useState<Teacher[]>();
    const [searchTerm, setSearchTerm] = useState<string>("");

    const clearForm = () =>
    {
        setFirstName("");
        setLastName("");
        setEmail("");
    }

    const getTeachers = () =>
    {
        if(organisationId)
        {
            axios.get(`/api/teacher/appusers/${organisationId}`, data.config)
            .then((response)=>
            {
                setTeachers(response.data)
            })
        }  
    }

    useEffect(()=>
    {
        getTeachers()
    },[]);

    useEffect(()=>
    {
        getTeachers()
    },[searchTerm]);


    const submit = async () => 
    {
        var currentErrors = []
        if(firstName === null || firstName.length === 0 ){
            currentErrors.push("Enter First Name")            
        }

        if(lastName === null || lastName.length === 0 ){
            currentErrors.push("Enter Last Name")            
        }

        if(email === null || email.length === 0  || !validateEmail(email)){
            currentErrors.push("Enter Email")            
        }

        if(role === null ||  !Number(role) ){
            currentErrors.push("Enter Role")            
        }

        if(organisationId === null){
            currentErrors.push("FATAL: Organisation not set")            
        }

        if(userId === null || userId.length === 0){
            currentErrors.push("UserId must be set")            
        }

        setErrors(currentErrors)

        if(currentErrors.length > 0)
        {
            return
        }

        var dataJSON:FormData =
        {
            firstName: firstName,
            lastName: lastName,
            email: email,
            roleId: role,
            organisationId: organisationId,
            userId: userId
        }

        await axios.post('/api/teacher',dataJSON, data.config)
        .then((response)=>
        {
            //getParents()
            clearForm()
            setMessages(["teacher Saved"])

            setTimeout(()=>
            {
                setMessages([])
               
            }, 3000)
        })

    }
    return (
        <div className='container'> 
            <h1>Teacher Panel</h1>

            <Messages messages={errors} />     
            <Messages messages={messages} />

            <div className='mt-5'>
                <div className='form-group'>
                    <label className='label'>First Name</label>
                    <input type="text" value={firstName} onChange={(e:React.FormEvent<HTMLInputElement>)=>{setFirstName(e.currentTarget.value)}}></input>
                </div>
                <div className='form-group'>
                    <label className='label'>Last Name</label>
                    <input type="text" value={lastName} onChange={(e:React.FormEvent<HTMLInputElement>)=>{setLastName(e.currentTarget.value)}}></input>
                </div>
                <div className='form-group'>
                    <label className='label'>Email</label>
                    <input type="email" value={email} onChange={(e:React.FormEvent<HTMLInputElement>)=>{setEmail(e.currentTarget.value)}}></input>
                </div>
                <div className='form-group'>
                    <label className='label'>UserId <a href={'https://epieos.com/'} rel="noreferrer" target='_blank'>find id</a></label>
                    <input type="email" value={userId} onChange={(e:React.FormEvent<HTMLInputElement>)=>{setUserId(e.currentTarget.value)}}></input>
                </div>
                <div className='form-group'>
                    <label className='label'>Role</label>
                    <select value={role} onChange={(e)=>{setRole(parseInt(e.currentTarget.value))}}>
                        {
                            Roles.map((role)=>
                            (
                                <option value={role.id}>{role.name}</option>
                            ))
                        }
                    </select>
                </div>
                <div className='mt-4'>
                    <button className='btn btn-success' onClick={submit}>Submit</button>
                </div>
            </div>
            <div>
            <div className='mt-3'>
                <div className="input-group mb-3">
                    <input onChange={(e:React.FormEvent<HTMLInputElement>)=>setSearchTerm(e.currentTarget.value)} type="text" className="form-control" placeholder="...name ...id" aria-label="name .. id" aria-describedby="basic-addon2"/>
                    <div className="input-group-append">
                        <span className="btn btn-outline-success" id="basic-addon2">Search</span>
                    </div>
                </div>
            </div>
            <table className='table table-responsive mt-3'>
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>First name</th>
                        <th>Last name</th>
                        <th>Email</th>
                        <th>Role</th>
                    </tr>
                </thead>
                <tbody>
                        {teachers?.map((teacher, index)=>
                        (
                            <tr>
                                <td>{teacher.id}</td>
                                <td>{teacher.firstName}</td>
                                <td>{teacher.lastName}</td>
                                <td>{teacher.email}</td>
                                <td>{teacher.role}</td>

                            </tr>
                        ))}
                </tbody>
            </table>
        </div>
        </div>
    )
}

export default AddEditTeacher