import {FC, useState} from 'react'
import axios from 'axios'
import {Modal, Button} from 'react-bootstrap';
interface Props
{
    status:string,
    data: Data,
    id:number,
    organisationId:string | null,
    refreshStudents: Function,
    setMessages: Function,
    model: string
}

interface Data 
{
    config:Config,
    id:string,
    isAuthenticated:Boolean
}

interface Config
{
    headers:Headers
}

interface Headers
{
    Authorization:string
}



const ArchiveModelButton:FC<Props> = ({status, data, id, organisationId, refreshStudents, setMessages, model}) =>
{    

    const [errors, setErrors] = useState<string[]>([])

    //modal
    const [isOpen, setIsOpen] = useState<boolean>(false);


    function openModal() {
        setIsOpen(true);
      }
    
    function closeModal() {
        setIsOpen(false);
    }


    const submit = async () => 
    {
        var currentErrors = []

        if(id === null)
        {
            currentErrors.push("student not set")            
        }       

        if(organisationId === null)
        {
            currentErrors.push("organisation not set")            
        }  

        setErrors(currentErrors)
        if(currentErrors.length > 0)
        {
            return
        }

        await axios.post(`/api/${model}/archive/${organisationId}/${id}`,{}, data.config)
        .then((response)=>
        {
            closeModal()
            setMessages([model+" updated"])
            setTimeout(()=>
            {
                refreshStudents()
                setMessages([])
               
            }, 2000)
        })      
    }
    
    return (
        <div>
            <button className='btn btn-outline-danger' onClick={()=> openModal()}>{status === "Archived"? "Un-archive": "Archive"}</button>

            <Modal show={isOpen} onHide={closeModal}>
                <Modal.Header closeButton>
                <Modal.Title>Are you sure you want to {status === "Archived"? "Un-archive": "Archive"} this {model}?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {model === "parent"? `all students will be ${status === "Archived"? "Un-archived.": "Archived."}`: null}<br/>
                    all their lessons will be {status === "Archived"? "Un-archived.": "Archived."}!<br/>

                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={closeModal}>
                    Close
                </Button>
                <Button variant="primary" onClick={submit}>
                    {status === "Archived"? "Un-archive": "Archive"}
                </Button>
                </Modal.Footer>
            </Modal>
          
        </div>
    )
}

export default ArchiveModelButton;