import {FC, useState} from 'react'
import axios from 'axios'
import {Modal, Button} from 'react-bootstrap';
import { getOrganisationIdFromURL } from '../HelperComponents/Functions';
interface Props 
{
    studentId:number,
    data: Data,
    refreshStudents: Function,
    setMessages: Function
}

interface Data 
{
    config:Config,
    id:string,
    isAuthenticated:Boolean
}

interface Config
{
    headers:Headers
}

interface Headers
{
    Authorization:string
}


const DeleteStudentButton:FC<Props> =  ({studentId, data, refreshStudents, setMessages}) =>
{

    const organisationId = getOrganisationIdFromURL();

    const [deleteModalIsOpen, setDeleteIsOpen] = useState<boolean>(false);

    function openDeletModal() {
        setDeleteIsOpen(true);
      }
    
    function closeDeletModal() {
        setDeleteIsOpen(false);
    }

    const deleteStudent = () =>
    {
        if(studentId > 0)
        {          
                
                axios.delete(`/api/student/delete/${organisationId}/${studentId.toString()}`, data.config)
                .then((response)=>
                {
                  setMessages(["Student updated"])
                  refreshStudents()
                }).catch((error)=>                
                {
                  setMessages(["Error"])
                  
                })
                setMessages([])
                closeDeletModal();               
        }
    }


    return (
    <div>
        <button disabled={true} className='btn btn-danger' onClick={openDeletModal}>Delete</button>
        <Modal show={deleteModalIsOpen} onHide={closeDeletModal}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure you want to permenantly delete this student?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div>
                <p>This means that all their related lessons, invoices and payment details will be deleted forever!</p>                
            </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeDeletModal}>
            Close
          </Button>
          <Button disabled={true} variant="primary" onClick={deleteStudent}>
            Delete (disabled temporarily)
          </Button>
        </Modal.Footer>
      </Modal>

    </div>)
}


export default DeleteStudentButton