import {FC, useState} from 'react'
import DatePicker from "react-datepicker";
import { formatDate, getOrganisationIdFromURL, downloadResource, getStartDateForRegister, getEndDateForRegister } from '../HelperComponents/Functions'
import Messages from '../HelperComponents/Messages'
import axios from 'axios'
import Organisations from '../../Enums/Organisations';
interface Props 
{
    data: Data
}

interface Data 
{
    config:Config,
    id:string,
    isAuthenticated:Boolean
}

interface Config
{
    headers:Headers
}

interface Headers
{
    Authorization:string
}

interface Stats 
{
    accountingStats: AccountingStats,
    teacherSalaries: Array<Salary>
    coverLessons: Array<CoverLesson>
    lessonsWithAdjustments:Array<AdjustmentLesson>,
    mslcTeacherSalaries:Array<MslcSalary>,
    totalFee: number
}

interface AccountingStats 
{
    revenue:string,
    salaries:string,
    numberOfLessons:string,
    oaFee:string,
    oaPlanOneFee:number,
    adminFee: number
}

interface Salary 
{
    id:string,
    firstName:string,
    lastName:string,
    payFromPaidLessons:string,
    payFromUnPaidLessons:string,
    payFromSelfInvoicedLessons:string,
    amountRefundedFromLessons:string,
    accountId:string
}

interface MslcSalary 
{
    id:string,
    firstName:string,
    lastName:string,
    pay:number
}

interface CoverLesson 
{
    id: string,
    teacher: string,
    student: string,
    hours: number,
    subject: string,
    lessonDate: string
}

interface AdjustmentLesson 
{
    teacher: string,
    student: string,
    fee: number,
    pay: number,
    padj:number,
    fadj:number
}


const Report:FC<Props> = ({data}) => 
{
    const organisationId = getOrganisationIdFromURL();
    const [salaries, setSalaries] = useState<Salary[] | null>(null);
    const [mslcSalaries, setMslcSalaries] = useState<MslcSalary[] | null>(null);
    const [accountingStats, setAccountingStats] = useState<AccountingStats | null>(null);
    const [coverLessons, setCoverLessons] = useState<CoverLesson[] | null>(null);
    const [adjustmentLessons, setAdjustmentLessons] = useState<AdjustmentLesson[] | null>(null);
    const [fee, setFee] = useState<number>(0);
    const [adminFee, setAdminFee] = useState<number>(0);

    const [fromDate, setFromDate] = useState<Date | null>(getStartDateForRegister(new Date(), true))
    const [toDate, setToDate] = useState<Date | null>(getEndDateForRegister(new Date(), true))

    const [errors, setErrors] = useState<string[]>([])

    const downloadCoverLessons = () => 
    {
        downloadResource("cover-lessons",{}, coverLessons)
    }

    const downloadAdjustmentLessons = () => 
    {
        downloadResource("adjustment-lessons",{}, adjustmentLessons)
    }

    const downloadSalaries = () => 
    {
        if(!mslcSalaries && !salaries)
        {
            alert('no salary data')
            return
        }
        let _salaries = organisationId === Organisations.MSLC? mslcSalaries : salaries;
        downloadResource("salaries",{}, _salaries)
    }

    const downloadReport = () => 
    {
        if(!accountingStats)
        {
            alert('no accounting stats')
            return 
        }
        var oaFee = parseFloat(accountingStats.oaFee)
        var newFee = oaFee + adminFee
        accountingStats.oaFee = "Total £" + newFee.toString() + " = (OA fee) " + oaFee + " + (Admin fee) " + adminFee; 

        accountingStats.adminFee = adminFee
        downloadResource("report",{}, [accountingStats])
    }

    const submit = (pageNumber:number) => 
    {
        var currentErrors = []
        if(fromDate == null)
        {
            currentErrors.push("Enter From Date")
        }

        if(toDate == null)
        {
            currentErrors.push("Enter To Date")
        }
              
        setErrors(currentErrors)

        if(currentErrors.length > 0)
        {
            return
        }

        var baseUrl = "/api/report/stats?"
        
        if(fromDate != null)
        {
            baseUrl += `fromDate=${formatDate(fromDate)}&`
        }

        if(toDate != null)
        {
            baseUrl += `toDate=${formatDate(toDate)}&`            
        }

        if(organisationId != null)
        {
            baseUrl += `organisationId=${organisationId}`            
        }

        axios.get(baseUrl, data.config)
                .then(response=>{
                    var data:Stats = response.data
                    setSalaries(data.teacherSalaries)
                    setAccountingStats(data.accountingStats)
                    setCoverLessons(data.coverLessons)   
                    setAdjustmentLessons(data.lessonsWithAdjustments)
                    setMslcSalaries(data.mslcTeacherSalaries)
                    setFee(data.totalFee);  
                })

    }

    return(
        <div className='container'>
            <h1>Report</h1>
            <Messages messages={errors} />     

            <div className='mt-3'>
                <div className='form-group'>
                    <label className='label'>From Date</label>
                    <DatePicker 
                            placeholderText='Select date' 
                            dateFormat={'dd/MM/yyyy'}
                            onChange={date => setFromDate(date)}
                            selected={fromDate}/>
                </div>
                <div className='form-group'>
                    <label className='label'>To Date</label>
                    <DatePicker 
                            placeholderText='Select date' 
                            dateFormat={'dd/MM/yyyy'}
                            onChange={date => setToDate(date)}
                            selected={toDate}/>
                </div>

                <div className='form-group'>
                    <label className='label'>Admin Fee</label>
                    <input defaultValue={adminFee} onChange={(e) => setAdminFee(parseFloat(e.target.value))} className='input' type="number"></input>


                <div className='mt-3 mb-3'>
                    <button className='btn btn-outline-success' onClick={()=>submit(1)}>Submit</button>
                </div>
                {
                    coverLessons && coverLessons.length > 0?
                        <div className='mt-3 mb-3'>
                            <button className='btn btn-outline-success' onClick={()=>downloadCoverLessons()}>Cover Lessons</button>
                        </div>
                    : <div>No cover lessons</div>
                }

                {
                    adjustmentLessons && adjustmentLessons.length > 0?
                        <div className='mt-3 mb-3'>
                            <button className='btn btn-outline-success' onClick={()=>downloadAdjustmentLessons()}>Adjustment Lessons</button>
                        </div>
                    : <div>No adjustment lessons</div>
                }

                {
                    (salaries && salaries.length > 0) || (mslcSalaries && mslcSalaries.length > 0)?
                        <div className='mt-3 mb-3'>
                            <button className='btn btn-outline-success' onClick={()=>downloadSalaries()}>Salaries</button>
                        </div>
                    : <div>No salaries</div>
                }

                {
                    accountingStats?
                        <div className='mt-3 mb-3'>
                            <button className='btn btn-outline-success' onClick={()=>downloadReport()}>Stats Report</button>
                        </div>
                    : <div>No stats</div>
                }
                {
                    accountingStats && accountingStats.oaFee?<div className='mt-3 mb-3'>
                    <button className='btn btn-outline-success'>OaFee: {accountingStats.oaFee}</button>
                </div>:<div>No fee</div>
                }
            </div>

          
            {/* <div id="Stats" className='mt-3 pl-5 pr-5'>

                <div id="StatsCard" className='card card-body'>
                    <div className='row'>
                        <div className='col'>
                            <FontAwesomeIcon icon={faMoneyBill}  /> Revenue
                        </div>
                        <div className='col'>
                            <strong>£{accountingStats?.revenue}</strong>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <FontAwesomeIcon icon={faMoneyBill}  /> Fees
                        </div>
                        <div className='col'>
                            <strong>£{fee}</strong>
                        </div>
                    </div>
                    <div className='row mt-3'>
                        <div className='col'>
                            <FontAwesomeIcon icon={faUsers}  /> Salaries
                        </div>
                        <div className='col'>
                            <strong>£{accountingStats?.salaries}</strong>
                        </div>
                    </div>
                    <div className='row mt-3'>
                        <div className='col'>
                            <FontAwesomeIcon icon={faChalkboardTeacher}  size='lg'  /> Lessons
                        </div>
                        <div className='col'>
                            <strong>{accountingStats?.numberOfLessons}</strong>
                        </div>
                    </div>
                </div>                

                <div className='mt-5'>
                    {
                       !mslcSalaries && salaries && salaries.length > 0 ? 
                            <SalariesTable data={data} salaries={salaries}/>    
                        : <div>No Salaries!!</div>
                    }

                    {
                        mslcSalaries && mslcSalaries.length > 0 ? 
                            <MslcSalariesTable data={data} salaries={mslcSalaries}/>    
                        : <div>No Salaries!!</div>
                    } 
                    
                </div>
                */}
                
            </div> 


           


        </div>
    )
}


export default Report