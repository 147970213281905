import {FC, useState, useEffect} from 'react'
import '../../../sass/settings.scss'
import axios from 'axios'
import {Modal, Button} from 'react-bootstrap';
import Messages from '../HelperComponents/Messages'
import { getOrganisationIdFromURL, formatDate } from '../HelperComponents/Functions';
import  InvoicePlans  from '../HelperComponents/BusinessLogic';
interface Props 
{
    data: Data
}

interface Data 
{
    config:Config,
    id:string,
    isAuthenticated:Boolean
}

interface Config
{
    headers:Headers
}

interface Headers
{
    Authorization:string
}

interface FormData 
{
    organisationId:string | null, 
    invoicePlanId:number | null
}


const Settings:FC<Props> =  ({data}) =>
{

    const organisationId = getOrganisationIdFromURL();

    const [currentPlan, setCurrentPlan] = useState<number | null>(null);
    const [errors, setErrors] = useState<string[]>([])
    const [messages, setMessages] = useState<string[]>([]);
    const [IsOpen, setIsOpen] = useState<boolean>(false);

    const [invoicePlan, setInvoicePlan] = useState<number | null>(null);


    useEffect(()=>
    {
        getCurrentPlan();
              
    },[])

    function getCurrentPlan()
    {
        if(organisationId != null)
        {
            axios.get(`/api/organisation/current-invoice-plan/${organisationId}`, data.config)
            .then((response)=>
            {
                setCurrentPlan(response.data);
            })
        }
    }


    function openModal() {
        setIsOpen(true);
      }
    
    function closeModal() {
        setIsOpen(false);
    }


    const selectInvoicePlan = (planId: number) => 
    {
        setInvoicePlan(planId);
        openModal();

    }

    const getUpdateDate = (date: Date) =>
    {
        let year = date.getFullYear();
        let month = date.getMonth();
        if(month === 11)
        {
            year = year+1;
            month = 0
        }
        else 
        {
            month += 1
        }

        return new Date(year, month, 25);
    }

    const submit = async () => 
    {
        var currentErrors = []

        if(invoicePlan === null)        
        {
            currentErrors.push("no invoice plan set")
        }

        if(organisationId === null)        
        {
            currentErrors.push("no app id set")
        }

        if(invoicePlan === currentPlan)        
        {
            currentErrors.push("you're already on this plan ")
        }

        var date = new Date();
        var calenderDate = date.getDate();

        if(calenderDate >=22  && calenderDate <= 26)
        {
            currentErrors.push("cannot update plan between 22nd or 26th")
        }

        setErrors(currentErrors)

        if(currentErrors.length > 0)
        {
            closeModal()
            return
        }

        var dataJSON:FormData =
        {
            organisationId: organisationId,
            invoicePlanId: invoicePlan
        }
        closeModal()
       
        await axios.post(`/api/organisation/invoice-plan/${organisationId}/${invoicePlan}`, dataJSON, data.config)
        .catch(error =>
        {
            console.log(error.response)
            console.log(error.response.request)
            let code = error.response.status;
            let message = error.response.data;
            let errorMsg = `Error: ${code} : ${message}`
            setErrors([errorMsg])
        })
        .then((response)=>
        {            
            console.log(response)
            var updateDate = getUpdateDate(date);

            setMessages(["Plan updated", `invoice plan will be active from: ${formatDate(updateDate)}`])
            
            getCurrentPlan()       

            setTimeout(()=>
            {
                setMessages([])
               
            }, 3000)
        })

    }
    

    return (
    <div className='container'>
        <h1>Settings</h1>       
        <Messages messages={errors} />     
        <Messages messages={messages} />
        <div className='mt-3'>
            <h4>Select a plan</h4>          
        </div>
        <hr/>
        {
            currentPlan != null?            
                <div>
                    <div className='card  bg-dark  mb-5'>
                        <div className='card-title'>
                            <h2>Current Plan</h2>
                        </div>
                        <div className='mb-3'>
                            <h5>{InvoicePlans[currentPlan??0].name}</h5>
                        </div>                   
                    </div>
                </div>
            :null
        }
        
        <div className='row'>
            <div id="SettingsCard" className='col' onClick={(e) => selectInvoicePlan(0)}>
                <div className='card active-card'>
                    <div className='card-title'>
                        Standard
                    </div>
                    <div className='mb-3'>
                        <h5>Pay & Go</h5>
                    </div>
                    <div>
                        <ul>
                            <li>Automated Invoicing</li>
                            <li>Pre-invoice</li>
                            <li>Fees are calcuated based on usage</li>
                        </ul>  
                    </div>                    
                </div>
            </div>
            {/* <div id="SettingsCard" className='col'>
                <div className='card grey-out'>
                    <div className='card-title'>
                        Pro
                    </div>
                    <div className='mb-3'>
                        <h5>Pay & Go</h5>
                    </div>
                    <div>
                        <ul>
                            <li>SMS/MMS</li>
                            <li>Automated Invoicing</li>
                            <li>Pre-invoice</li>
                            <li>Fees are calcuated based on usage</li>
                        </ul>  
                    </div>                    
                </div>
            </div> */}
            <div id="SettingsCard" className='col' onClick={(e) => selectInvoicePlan(2)}>
                <div className='card active-card'>
                    <div className='card-title'>
                        Business
                    </div>
                    <div className='mb-3'>
                        <h5>Rev share</h5>
                    </div>
                    <div>
                        <ul>                            
                            <li>Automated Email Invoicing</li>
                            <li>Pre-invoice</li>
                            <li>Fees are based on transactions 3%</li>
                        </ul>  
                    </div>                    
                </div>
            </div>
        </div>

        <Modal show={IsOpen} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure you want to select this plan?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div>Moving to:  {InvoicePlans[invoicePlan??0].name}</div>
            <div>{InvoicePlans[invoicePlan??0].message}</div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
          <Button variant="primary" onClick={submit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

    </div>)
}


export default Settings