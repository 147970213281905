import {FC, useState} from 'react'
import InvoicePanel from '../Invoice/InvoicePanel'
import Payout from './Payouts/Payout'
import AccountInfo from './AccountInfo'
import { DashboardProps } from '../../Types/types'
import '../../../sass/dashboard.scss'


const Dashboard:FC<DashboardProps> = ({data}) => 
{
  

    const [panels, setPanels] = useState<number[]>([0]);

    const showHidePanels = (panel: number) => 
    {
        if(panels.includes(panel)){
            let index = panels.indexOf(panel)
            if(index > -1){            
                setPanels(panels.filter(x => x !== panel))
            }
        }else
        {
            setPanels([...panels, panel])
        }  
    }

    return (  
        <div className='container'>
            
            <div className='row mt-5'>
                <div className='col-2 mt-5'>
                    <div className=''>
                        <button className={'mb-3 btn btn-outline-primary w-100 ' + (panels.includes(0)? 'active' : '')} onClick={()=> showHidePanels(0)}>Account Info</button>
                        <button className={'mb-3 btn btn-outline-primary w-100 ' + (panels.includes(1)? 'active' : '')} onClick={()=> showHidePanels(1)}>Invoices</button>
                        <button className={'mb-3 btn btn-outline-primary w-100 ' + (panels.includes(2)? 'active' : '')} onClick={()=> showHidePanels(2)}>Payouts</button> 
                        <button className={'mb-3 btn btn-outline-primary w-100 '}>Report</button>                 
                    </div>
                </div>
                <div className='col-10'>
                    {panels.includes(0)?  <AccountInfo data={data}/>: null}
                    {panels.includes(1)? <InvoicePanel data={data}/> : null}
                    {panels.includes(2)? <Payout data={data}/> : null}
                    {/* {panel == 3? <Report data={data}/> : null} */}
                </div>
            </div>
        </div>
    )
}


export default Dashboard