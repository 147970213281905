import { jsPDF } from "jspdf";
import  autoTable  from 'jspdf-autotable'
import { formatDateDDMMYYYY, getPrevMonth, getPrevYear} from './Functions'
import logo from '../LegacyComponents/Images/logo';
interface Lesson 
{
    teacher: string,
    student: string,
    totalFee: string,
    lessonDate: string,
    hours: number,
    subject: string,
    fee:string,
    lessonCode:string
}

interface BillingData 
{
    company: string,
    bank: string,
    account: string,
    sortCode: string,
    accountNo: string
}

function getInvoicePeriod(year: number, month: number): string
{
    return "26-"+ getPrevMonth(month) + "-" + getPrevYear(month, year)  + " to 25-" + month + "-" + year 
}
export async function createInvoicePdf(parentId: number, name: string, lessons:Lesson[], year: number, month: number, billingData: BillingData, isLegacy: boolean) {

    const doc = new jsPDF();    
    if(isLegacy)
    {
        await doc.addImage(logo, 'JPEG', 90, 2, 45, 18.5);
    }    
    let sum = 0;
    await lessons.forEach(value => { sum += parseFloat(value.totalFee) });
    
    let _lessons = await lessons.map(async(l) => await [l.student, formatDateDDMMYYYY(new Date(l.lessonDate)), l.subject, l.lessonCode, l.hours, l.fee, l.totalFee])
    
    Promise.all(_lessons).then(async(tableData)=>
    {
        let y = 20;
        let width = 200;
        let margin = 15;
        let company = billingData?.company;
        let bank  = billingData?.bank;
        let account = billingData?.account;
        let sortCode = billingData?.sortCode;
        let accountNo = billingData?.accountNo;

        var normalFontSize = 8
        var normalMargin = 4
        var newSectionMargin = 6

        
        await doc.addFont('helvetica', 'courier', 'normal');
        await doc.setFont('helvetica');
        
        await doc.setFontSize(12).setFont('helvetica', 'bold');
        await doc.text('Invoice', width - margin - 40, y += normalMargin);
        await doc.setFontSize(normalFontSize).setFont('helvetica', 'normal');
        await doc.text(`Invoice ID: ${year?.toString() + month?.toString() + parentId.toString()}`, width - margin - 40, y += 7);        

        await doc.setFontSize(normalFontSize).setFont('helvetica', 'bold');
        await doc.text(`Cheques payable to:`, margin, y += newSectionMargin);
        await doc.setFontSize(normalFontSize).setFont('helvetica', 'normal');
        await doc.text(company, margin + 45, y);

        await doc.setFontSize(normalFontSize).setFont('helvetica', 'bold');
        await doc.text(`Bank transfers to:`, margin, y += normalMargin);
        await doc.setFontSize(normalFontSize).setFont('helvetica', 'normal');
        await doc.text(company, margin + 45, y);

        await doc.setFontSize(normalFontSize).setFont('helvetica', 'bold');
        await doc.text(`Bank:`, margin, y += normalMargin);
        await doc.setFontSize(normalFontSize).setFont('helvetica', 'normal');
        await doc.text(bank, margin + 45, y);

        await doc.setFontSize(normalFontSize).setFont('helvetica', 'bold');
        await doc.text(`Account name:`, margin, y += normalMargin);
        await doc.setFontSize(normalFontSize).setFont('helvetica', 'normal');
        await doc.text(account, margin + 45, y);

        await doc.setFontSize(normalFontSize).setFont('helvetica', 'bold');
        await doc.text(`Sort code:`, margin, y += normalMargin);
        await doc.setFontSize(normalFontSize).setFont('helvetica', 'normal');
        await doc.text(sortCode, margin + 45, y);

        await doc.setFontSize(normalFontSize).setFont('helvetica', 'bold');
        await doc.text(`Account No:`, margin, y += normalMargin);
        await doc.setFontSize(normalFontSize).setFont('helvetica', 'normal');
        await doc.text(accountNo, margin + 45, y);

        await doc.setFontSize(normalFontSize).setFont('helvetica', 'bold');
        await doc.text(`Billed to:`, margin, y += normalMargin);
        await doc.setFontSize(normalFontSize).setFont('helvetica', 'normal');
        await doc.text(name, margin + 45, y);

        await doc.setFontSize(normalFontSize).setFont('helvetica', 'bold');
        await doc.text(`Invoice Period:`, margin, y += normalMargin);
        await doc.setFontSize(normalFontSize).setFont('helvetica', 'normal');
        await doc.text(getInvoicePeriod(year, month), margin + 45, y);

        await doc.setFontSize(normalFontSize).setFont('helvetica', 'bold');
        await doc.text(`Invoice total:`, margin, y += normalMargin);
        await doc.setFontSize(normalFontSize).setFont('helvetica', 'normal');
        await doc.text(`£${sum}`, margin + 45, y);
        await autoTable(doc, {
            margin: { top: y += 5 },
            head: [['Student', 'Date', 'Subject', 'Type', 'Hours', 'Price', 'Fee']],
            body: tableData,
          })

        await autoTable(doc,{
            theme:'grid',
            styles: { cellWidth: 35 },
            margin: { top: y += 20, left: 100 },   
            head:[['Key','Definition']],
            body:[
                ['t', 'trial lesson'],
                ['c','cover lesson'],
                ['m', 'missed lesson'],
                ['e', 'extra lesson']
            ]
        })

        await doc.save(`${name}.pdf`);
    })
    
}